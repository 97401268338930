import React from 'react';

const AboutBanner = (props) => {
  return (
    <div className='flex mx-auto my-20 px-10 justify-center items-center max-w-7xl h-[450px] rounded'
    style={{backgroundImage: `url(${props.image})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center center'}}>
        <h3 className='font-semibold text-white text-[42px] text-center max-w-2xl leading-[50px]'>
            Each treat is a testament to our devotion to the 
            well-being of your pets and ours.
        </h3>
    </div>
  )
}

export default AboutBanner;