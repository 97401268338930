import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

function ProtectAdmin({ children }) {
  const [uid, setUid] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        console.log("user is logged out login again");
        navigate("/Login");
      }
    });
  }, []);

  if (uid != "" && uid == localStorage.getItem("uid")) {
    return children;
  } else {
    navigate("/Login");
  }
}

export default ProtectAdmin;
