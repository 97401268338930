import React, { useState } from "react";
import { BlogCard, BlogLayout } from "../components";
import {
  FaArrowLeft,
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { db } from "../firebase";
import {
  collection,
  setDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";

const AddBlog = () => {
  const [blogCardData, setBlogCardData] = useState({
    shortTitle: "",
    longTitle: "",
    image: null,
    description: "",
  });

  const [blogContent, setBlogContent] = useState([]);
  const [blogContentData, setBlogContentData] = useState({
    format: "",
    heading: "",
    image: null,
    content: "",
  });

  const handleCardInputChange = (e) => {
    const { id, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    setBlogCardData({
      ...blogCardData,
      [id]: newValue,
    });
  };
  const handleBlogInputChange = (e) => {
    const { id, value, type, files } = e.target;
    let newValue = type === "file" ? files[0] : value;
    if (id === "content") {
      newValue = newValue.replace(/\r?\n/g, "\n");
    }
    setBlogContentData({
      ...blogContentData,
      [id]: newValue,
    });
  };
  const handleBuildBlog = (e) => {
    e.preventDefault();
    let newContent = {};
    if (blogContentData.format === "img/content") {
      newContent = {
        format: blogContentData.format,
        image: blogContentData.image,
        content: blogContentData.content,
      };
    } else if (blogContentData.format === "standard") {
      newContent = {
        format: blogContentData.format,
        heading: blogContentData.heading,
        content: blogContentData.content,
      };
    } else if (blogContentData.format === "heading") {
      newContent = {
        format: blogContentData.format,
        heading: blogContentData.heading,
      };
    } else if (blogContentData.format === "card") {
      newContent = {
        format: blogContentData.format,
        content: blogContentData.content,
      };
    } else if (blogContentData.format === "option") {
      newContent = {
        format: blogContentData.format,
        heading: blogContentData.heading,
      };
    } else if (blogContentData.format === "optionCard") {
      newContent = {
        format: blogContentData.format,
        content: blogContentData.content,
      };
    }
    setBlogContent([...blogContent, newContent]);
    setBlogContentData({
      format: "",
      heading: "",
      image: null,
      content: "",
    });
  };
  const handleFormatChange = (e) => {
    const selectedFormat = e.target.value;

    if (selectedFormat !== blogContentData.format) {
      setBlogContentData({
        format: selectedFormat,
        heading: "",
        image: null,
        content: "",
      });
    }
  };
  const addBlogToDB = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm("Are you sure you want to submit the blog to the database?");

    if (!confirmed) {
      return; // User canceled the submission
    }

    try {
      const storage = getStorage(); // Initialize Firebase Storage

      // Step 1: Upload the image to Firebase Storage
      const imageRef = ref(storage, `BlogImages/${blogCardData.shortTitle}`);
      await uploadBytes(imageRef, blogCardData.image);

      // Step 2: Get the download URL for the uploaded image
      const imageUrl = await getDownloadURL(imageRef);

      // Step 3: Store the download URL as a reference in Firestore
      const blogsRef = collection(db, "blogs");
      const querySnapshot = await getDocs(blogsRef);
      let newId = querySnapshot.size + 1;

      const blogData = {
        id: newId,
        shortTitle: blogCardData.shortTitle,
        longTitle: blogCardData.longTitle,
        image: imageUrl, // Store the download URL as the image reference
        description: blogCardData.description,
      };

      const newBlogDoc = doc(blogsRef, newId.toString());
      await setDoc(newBlogDoc, blogData);

      const contentCollectionRef = collection(newBlogDoc, "content");

      for (let i = 0; i < blogContent.length; i++) {
        if (blogContent[i].image) {
          // Upload each content image to Firebase Storage
          const contentImageRef = ref(
            storage,
            `BlogImages/Blog${newId}Img${i + 1}`
          );
          await uploadBytes(contentImageRef, blogContent[i].image);
          blogContent[i].image = await getDownloadURL(contentImageRef);
        }
        const newContentRef = doc(contentCollectionRef, (i + 1).toString());

        await setDoc(newContentRef, {
          id: (i + 1),
          format: blogContent[i].format,
          heading: blogContent[i].heading || null,
          image: blogContent[i].image || null,
          content: blogContent[i].content || null,
        });
      }

      window.alert("Blog added to Firestore successfully.");
      } catch (error) {
        window.alert("Error adding blog to Firestore: " + error.message);
      }
  };
  
  return (
    <React.Fragment>
      <div className="flex-col justify-center max-w-6xl mx-8 my-16 md:mx-auto">
        <div className="flex justify-center">
          <h1 className="text-4xl font-semibold md:text-5xl">
            Create a new Blog Post
          </h1>
        </div>
        <div>
          <form id="blogForm" onSubmit={addBlogToDB} className="flex-col mx-auto my-16 w-[500px]">
            <div className="flex items-center justify-between my-8">
              <label>Short title:</label>
              <input
                maxLength={27}
                onChange={handleCardInputChange}
                type="text"
                id="shortTitle"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in blog card"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Long title:</label>
              <input
                onChange={handleCardInputChange}
                type="text"
                id="longTitle"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears at top of blog page"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Main Image:</label>
              <input
                onChange={handleCardInputChange}
                type="file"
                id="image"
                accept="image/*"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Description:</label>
              <textarea
                onChange={handleCardInputChange}
                minLength={170}
                maxLength={205}
                type="text"
                id="description"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in blog card (170-205) characters"
                required
              />
            </div>
          </form>
        </div>
        {blogCardData.image !== null && (
          <div className="flex-col mx-auto w-fit">
            <h3 className="text-2xl font-semibold text-center md:text-3xl">
              Blog Card Sample
            </h3>
            <BlogCard
              blog={{
                ...blogCardData,
                image: URL.createObjectURL(blogCardData.image),
              }}
            />
          </div>
        )}
        <div>
          {blogCardData.longTitle && blogCardData.image ? (
            <>
              <div className="flex mx-10 mt-20 md:relative">
                <div className="mx-auto w-full max-w-[950px]">
                  <button className="flex text-[#005c7b]">
                    <FaArrowLeft />
                  </button>
                </div>
              </div>
              <div className="flex mx-10 mt-8 mb-20">
                <div className="mx-auto max-w-[950px]">
                  <div className="flex flex-col justify-between w-[950px] md:flex-row">
                    <h1 className="leading-[50px] font-bold max-w-3xl mx-auto text-4xl lg:mx-0 md:leading-[70px] md:text-5xl">
                      {blogCardData.longTitle}
                    </h1>
                    <div className="flex mt-10 space-x-4 md:mb-2 md:mt-auto">
                      <LinkedinShareButton title={blogCardData.longTitle}>
                        <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                          <FaLinkedinIn color="#005c7b" size={20} />
                        </div>
                      </LinkedinShareButton>
                      <FacebookShareButton hashtag="#justnaturalpaws">
                        <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                          <FaFacebookF color="#005c7b" size={20} />
                        </div>
                      </FacebookShareButton>
                      <TwitterShareButton hashtags={["#justnaturalpaws"]}>
                        <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                          <FaTwitter color="#005c7b" size={20} />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                  <div className="justify-between xl:flex xl:flex-wrap">
                    {blogContent &&
                      blogContent.map((section, index) =>
                        section.image ? (
                          <BlogLayout
                            key={index}
                            section={{
                              ...section,
                              image: URL.createObjectURL(section.image),
                            }}
                          />
                        ) : (
                          <BlogLayout key={index} section={section} />
                        )
                      )}
                    {blogContentData.image ? (
                      <BlogLayout
                        section={{
                          ...blogContentData,
                          image: URL.createObjectURL(blogContentData.image),
                        }}
                      />
                    ) : (
                      <BlogLayout section={blogContentData} />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div>
          <h3 className="text-3xl font-semibold text-center">
            Add content to the blog
          </h3>
          <form className="flex-col mx-auto my-16 w-[500px]">
            <div className="flex items-center justify-between my-8">
              <label htmlFor="format">Format:</label>
              <select
                id="format"
                name="format"
                value={blogContentData.format}
                onChange={handleFormatChange}
                required
              >
                <option value="">Select an Option</option>
                <option value="img/content">Image with Content</option>
                <option value="standard">Heading and Content</option>
                <option value="heading">Large Card Heading</option>
                <option value="card">Large Card</option>
                <option value="option">Small Card Heading</option>
                <option value="optionCard">Small Card</option>
              </select>
            </div>
            {blogContentData.format === "img/content" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Content Image:</label>
                  <input
                    onChange={handleBlogInputChange}
                    type="file"
                    id="image"
                    accept="image/*"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    required
                  />
                </div>
                <div className="flex items-center justify-between my-8">
                  <label>Content:</label>
                  <textarea
                    onChange={handleBlogInputChange}
                    type="text"
                    id="content"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter content"
                    required
                  />
                </div>
              </>
            )}
            {blogContentData.format === "standard" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Heading:</label>
                  <input
                    onChange={handleBlogInputChange}
                    type="text"
                    id="heading"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter heading"
                    required
                  />
                </div>
                <div className="flex items-center justify-between my-8">
                  <label>Content:</label>
                  <textarea
                    onChange={handleBlogInputChange}
                    type="text"
                    id="content"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter content"
                    required
                  />
                </div>
              </>
            )}
            {blogContentData.format === "heading" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Heading:</label>
                  <input
                    onChange={handleBlogInputChange}
                    type="text"
                    id="heading"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter heading"
                    required
                  />
                </div>
              </>
            )}
            {blogContentData.format === "card" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Content:</label>
                  <textarea
                    onChange={handleBlogInputChange}
                    type="text"
                    id="content"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter content"
                    required
                  />
                </div>
              </>
            )}
            {blogContentData.format === "option" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Heading:</label>
                  <input
                    onChange={handleBlogInputChange}
                    type="text"
                    id="heading"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter heading"
                    required
                  />
                </div>
              </>
            )}
            {blogContentData.format === "optionCard" && (
              <>
                <div className="flex items-center justify-between my-8">
                  <label>Content:</label>
                  <textarea
                    onChange={handleBlogInputChange}
                    type="text"
                    id="content"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter content"
                    required
                  />
                </div>
              </>
            )}
            <div className="flex items-center my-8">
              <button
                onClick={handleBuildBlog}
                type="submit"
                className="bg-[#005c7b] rounded-lg px-4 py-2 mx-auto"
              >
                Add Content
              </button>
            </div>
          </form>
          <button className="flex p-2 mx-auto mt-20 border rounded-lg" type="submit" form="blogForm">
            Submit to DB
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBlog;
