import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useSelector } from 'react-redux';

const ProductSection = (props) => {
  const products = useSelector((state)=> state.products.products);
  return (
    <div className='mt-20'>
        <h2 className='text-4xl font-semibold text-center md:text-5xl'>
            Shop our treats
        </h2>
        {props.subHeading ? (
          <div className='flex justify-center mx-8'>
            <p className='max-w-sm mx-auto mt-6 text-sm text-center md:text-base'>
              Made with love and loaded with nutrition for your furry family members.
            </p>
          </div>
        ):(
          <div className='flex justify-center mx-8'>
            <p className='max-w-sm mx-auto mt-6 text-sm text-center md:text-base'>
              As one of the fastest growing pet treat companies in North America we ensure our products 
              are full of natural and healthy ingredients!
            </p>
          </div>
        )}
        <div className="flex-wrap justify-center mx-auto my-8 md:flex">
          {
            products.map((product, index) =>(
              <ProductCard key={index} data={product} />))
          }
        
        </div>
    </div>
  )
}

export default ProductSection;