import React, {useEffect, useState, useRef} from 'react'

const ImageCarousel = (props) => {
    const [image, setImage] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const imageRefs = useRef([]);

    useEffect(() => {
        props.images && setImage(props.images[0]);
    },[props.images]);

    const selectImg = (selectedImage, index) => {
        if(index === currentIndex){
            return;
        }
        setImage(selectedImage);
        setCurrentIndex(index);
        imageRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    }

    if(!props.images || props.images.length === 0){
        return null;
    }
  return (
    <div>
        <img src={image} alt='product' className='object-cover pb-10 m-auto h-96 lg:h-full'/>
        <div className='flex max-w-[312px] md:max-w-[460px] mx-auto space-x-2 overflow-x-auto'>
            {props.images.map((img, index) => (
                <button key={index}
                ref={(ref) => (imageRefs.current[index] = ref)} 
                className={`${img === image ? 'border-2 border-[#005c7b]' : 'border-gray-200'} border rounded-lg w-[70px] h-[70px] overflow-hidden flex-shrink-0`}
                onClick={() => selectImg(img, index)}>
                    <img src={img} alt='thumbnail' className='object-cover h-full mx-auto'/>
                </button>
            ))}
        </div>
    </div>
  )
}

export default ImageCarousel;