import React, {useEffect} from 'react';
import { ProductSection } from '../components';

const ShopPage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  },[]);
  return (
    <>
      <ProductSection subHeading={true}/>
      <h2 className="px-10 mt-20 mb-8 text-4xl font-bold text-center">Are you a retailer?</h2>
      <div className="flex justify-center">
        <iframe title="Are you a retailer?" src="https://www.faire.com/embed/bw_2x4ww6qtej" 
        width="900" height="600"></iframe>
      </div>
    </>
  )
}

export default ShopPage;