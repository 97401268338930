import React from "react";
// import DogPic5 from "../../images/DogPic5.jpg";
// import GetToKnowUs from "../../images/GetToKnowUs.jpg";
// import DogPic6 from "../../images/DogPic6.jpg";
// import DogPic7 from "../../images/DogPic7.jpg";
// import DogPic8 from "../../images/DogPic8.jpg";

const AboutImgCollage = () => {
  return (
    <div className="flex-col justify-center max-w-6xl px-10 mx-auto mb-20">
      <h1 className="text-3xl font-semibold text-center md:text-4xl">
        Our furry friends
      </h1>
      <p className="mx-auto my-4 leading-8 max-w-[725px] text-center">
        When you choose our treats, you're not just indulging your pets - you're
        contributing to a global movement of compassion and care. Every treat is
        a little gesture of love that reinforces the bond between you and your
        four-legged companions.
      </p>
      <div className="flex flex-wrap justify-center max-w-5xl mx-auto">
        <div className="flex md:min-w-[400px] max-h-[600px] md:w-2/5 overflow-hidden">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic5.jpg?alt=media&token=057fe8f5-d454-4043-99e7-7c29f2913105"
            }
            alt="img"
            className="object-cover w-full max-h-full p-2 rounded-2xl"
          />
        </div>
        <div className="flex flex-wrap max-h-[600px] md:w-3/5">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FGetToKnowUs.jpg?alt=media&token=6ee0dbff-11d4-41ea-ae3e-bd80dff211d9"
            }
            alt="img"
            className="object-cover w-1/2 p-2 rounded-2xl h-1/2"
          />
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic6.jpg?alt=media&token=95352024-749a-4b72-b336-a7cb7d3fa004"
            }
            alt="img"
            className="object-cover w-1/2 p-2 rounded-2xl h-1/2"
          />
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic7.jpg?alt=media&token=4bb0a0f3-9797-4bd5-aacd-6352d8713384"
            }
            alt="img"
            className="object-cover w-1/2 p-2 rounded-2xl h-1/2"
          />
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic8.jpg?alt=media&token=18c5c730-115e-4729-90e2-d4efe1329f3a"
            }
            alt="img"
            className="object-cover w-1/2 p-2 rounded-2xl h-1/2"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutImgCollage;
