import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
export const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const onChangeForm = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (formData.email != "" && formData.password != "") {
      signInWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          localStorage.setItem("uid", user.uid);
          navigate("/Admin/Home");
        })
        .catch((error) => {
          console.log(errorMessage);
          setErrorMessage("Email or Password Incorrect");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        navigate("/Admin/Home");
        console.log("uid", uid);
      } else {
        console.log("user is logged out login again");
      }
    });
  }, []);

  return (
    <div className="flex justify-center mt-10">
      <div className="bg-white p-8 rounded shadow-lg w-80">
        <h1 className="text-center text-3xl font-extrabold text-gray-900 mb-8">
          Login
        </h1>
        <div>
          <label htmlFor="email" className="block mb-2 text-sm font-medium">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b] block w-full p-2.5"
            placeholder="Email"
            required
            onChange={onChangeForm}
            value={formData.email}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password" className="block mb-2 text-sm font-medium">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b] block w-full p-2.5"
            placeholder="Password"
            required
            onChange={onChangeForm}
            value={formData.password}
          />
        </div>
        <div className="mt-6">
          <button
            className="w-full py-2 text-white text-sm font-normal bg-[#005c7b] rounded-[3px]"
            onClick={onSubmitForm}
          >
            Login
          </button>
        </div>
        {errorMessage != "" ? (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-fit mt-2"
            role="alert"
          >
            <strong class="font-bold">{errorMessage}</strong>
            {/* <span class="block sm:inline">will get back to you soon</span> */}
            {/* <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg class="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
              </span> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};
