import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import CartRow from "../components/CartRow/CartRow";
import PaymentForm from "../components/PaymentForm/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const CheckoutPage = () => {
  const [proceed, setProceed] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [paymentIntentID, setPaymentIntentID] = useState("");
  const cartItems = useSelector((state) => state.cart.items);

  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numberOfItems = () => {
    return cartItems.reduce((total, cartItem) => {
      return total + cartItem.quantity;
    }, 0);
  };

  const handleProceedToPayment = () => {
    if (!proceed && numberOfItems() > 4) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  };

  const createPaymentIntentBody = (cartItems) => {
    const keys = ["id", "price", "quantity"]; // no need to send other product details to backend
    return cartItems.map((item) => {
      let newObj = {};
      keys.forEach((k) => {
        newObj[k] = item[k];
      });
      return newObj;
    });
  };

  useEffect(() => {
    if (proceed) {
      fetch("/api/config")
        .then(async (r) => {
          const { publishableKey } = await r.json();
          setStripePromise(loadStripe(publishableKey));
        })
        .catch((e) => {
          console.error(e.message);
          setErrorMsg(
            "Error while loading payment page! Please refresh the page."
          );
        });
    }
  }, [proceed]);

  useEffect(() => {
    if (proceed) {
      fetch("/api/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createPaymentIntentBody(cartItems)),
      })
        .then(async (r) => {
          const { client_secret: clientSecret, id: paymentIntentID } =
            await r.json();
          setClientSecret(clientSecret);
          setPaymentIntentID(paymentIntentID);
        })
        .catch((e) => {
          console.error(e.message);
          setErrorMsg(
            "Error while loading payment page! Please refresh the page."
          );
        });
    }
  }, [cartItems, proceed]);

  return (
    <div className="justify-center justify-between max-w-6xl mx-auto my-16 lg:flex w-7/8">
      <div>
        <div className="mx-8 border-b-2 lg:relative lg:top-[24px]">
          <button
            onClick={back}
            className="my-6 flex justify-center items-center w-fit font-semibold text-[#005c7b]"
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Continue Shopping
          </button>
        </div>
        <div className="mx-8 my-8 lg:relative lg:top-[24px]">
          <h4 className="mb-2 font-semibold">Shopping Cart</h4>
          <p>
            You have {numberOfItems()} item{numberOfItems() !== 1 ? "s" : ""} in
            your cart.
          </p>
        </div>
        <CartRow proceed={proceed} />
      </div>
      <div className="flex justify-center mx-8 mt-10 bg-gray-100 lg:w-2/5 rounded-2xl">
        <div className="w-full mx-4 my-10">
          {!proceed && ( // Render "Proceed to Payment" button if user has not proceeded
            <div className="w-full mx-auto my-auto">
              {numberOfItems() < 5 ? (
                <h4 className="font-semibold md:text-lg text-center text-[#005c7b]">
                  You need at least 5 items to checkout.
                </h4>
              ) : (
                <button
                  onClick={handleProceedToPayment}
                  className="my-2 w-full font-semibold text-white bg-[#005c7b] rounded-lg p-2"
                >
                  Proceed to Payment
                </button>
              )}
            </div>
          )}
          {proceed && stripePromise && clientSecret && (
            <React.Fragment>
              <div className="flex justify-center mb-2">
                <button
                  onClick={handleProceedToPayment}
                  className="my-2 flex justify-center items-center w-fit font-semibold text-white bg-[#005c7b] rounded-lg p-2"
                >
                  Change Items In Cart
                </button>
              </div>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm
                  cartItems={cartItems}
                  paymentIntentID={paymentIntentID}
                />
              </Elements>
            </React.Fragment>
          )}
          {errorMsg && <p className="text-red-600">{errorMsg}</p>}
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
