import React, { useState } from "react";

const TikTokSection = () => {
  const [videoOn, setVideoOn] = useState(false);

  const videoClick = () => {
    const video = document.getElementById("tiktokvideo");
    if (!videoOn) {
      video.play();
      setVideoOn(true);
    } else {
      video.pause();
      setVideoOn(false);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center px-6 m-4 w-7/8">
      <div className="w-full max-w-6xl md:flex md:space-x-16">
        <div className="relative flex items-center justify-center w-full my-12 md:w-2/5">
          <div
            style={{
              backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FiPhone.jpg?alt=media&token=4071ec8f-60bb-415b-ae60-a90bd7d414ef")`,
            }}
            className="z-50 relative bg-cover mx-auto p-3 flex justify-center 
            shadow-[50px_35px_100px_-15px_rgba(156,158,172,1)] rounded-[43px] h-[550px] w-[270px]"
          >
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FiPhoneTop.jpg?alt=media&token=0ee0ca72-1651-49d0-96f0-95d9490f3b88"
              }
              alt="iphoneTop"
              className="absolute top-[11px] left-[72px] w-32"
            />
            <div className="flex ml-[2px] justify-center rounded-[30px] overflow-hidden">
              <video
                onClick={videoClick}
                id="tiktokvideo"
                loop
                playsInline
                poster="https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FPlaceholder.png?alt=media&token=23477766-62cd-488c-88d5-266aec91fa5b"
                className={`h-full min-w-min ${videoOn ? "active" : ""}`}
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FVideo%20Ad.mp4?alt=media&token=b7d88c9f-447d-4b6e-a83d-288407d65a69"
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FTikTokHighlight.jpg?alt=media&token=d1f28792-dd4b-4426-9049-501ff09da67b"
            }
            alt="bg"
            className="absolute z-0 hidden w-44 h-44 -right-6 -top-28 xl:block"
          />
        </div>
        <div className="justify-center md:flex md:flex-col md:w-3/5">
          <h3 className="text-3xl font-semibold md:text-5xl">
            Checkout our TikTok
          </h3>
          <p className="max-w-xs my-8 leading-8">
            Dive into our fun-filled videos and see the paw-sitive impact we're
            making!
          </p>
          <a
            href="https://www.tiktok.com/@justnaturalpaws"
            rel="noreferrer"
            target="_blank"
            className="border-2 border-[#005c7b] rounded flex items-center justify-center w-fit px-12 py-2 text-center
          text-[#005c7b] transition-transform transform hover:scale-105"
          >
            Visit Our TikTok
          </a>
        </div>
      </div>
    </div>
  );
};

export default TikTokSection;
