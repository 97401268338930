import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../actions/cartActions";
import { updateProductItem } from "../actions/productActions";

const OrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const products = useSelector((state) => state.products.products);
  const [cartItemsDisplay, setCartItemsDisplay] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const clientSecret = queryParams.get("payment_intent_client_secret");

    if (clientSecret) {
      // Clean up the URL
      queryParams.delete("payment_intent_client_secret");
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, []);

  useEffect(() => {
    const paymentIntent = queryParams.get("payment_intent");
    fetch(`/api/retrieve_payment_intent/${paymentIntent}`)
      .then(async (r) => {
        const response = await r.json();
        setTotalAmount(response?.amount);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, []);

  const home = () => {
    navigate("/");
  };

  const clearItems = () => {
    dispatch(clearCart());
    for (const product of products) {
      dispatch(updateProductItem(product.id, 0));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setCartItemsDisplay(cartItems);
    clearItems();
  }, []);

  const cart = () => {
    // const totalAmount = cartItemsDisplay.reduce(
    //   (total, item) => total + item.price * item.quantity,
    //   0
    // );

    return (
      <div className="max-w-lg mx-auto">
        <ul className="p-4 rounded-lg">
          {cartItemsDisplay.map((item) => (
            <li
              key={item.name}
              className="flex items-center justify-between py-2 border-b"
            >
              <span>
                {item.quantity} x {item.name}
              </span>
              <span>${(item.price * item.quantity).toFixed(2)}</span>
            </li>
          ))}
          <li className="flex items-center justify-between py-2">
            <span className="font-bold">Total (HST included):</span>
            <span className="font-bold">${totalAmount / 100}</span>
          </li>
        </ul>
      </div>
    );
  };
  return (
    <div className="justify-center justify-between max-w-6xl mx-auto my-16 lg:flex w-7/8">
      <div className="w-full">
        <div className="mx-8 border-b-2 lg:relative lg:top-[24px]">
          <button
            onClick={home}
            className="my-6 flex justify-center items-center w-fit font-semibold text-[#005c7b]"
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Home
          </button>
        </div>
        <div className="justify-center mx-8 my-8">
          <div className="flex justify-center mx-auto">
            <div>
              <div className="mx-auto w-fit h-fit">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2Fcelebration.webp?alt=media&token=3d936c17-7048-4144-ba06-8c72e7dc89a8"
                  alt="celebration"
                  className="w-full h-full rounded-full max-w-96 max-h-96"
                />
              </div>
              <h2 className="font-bold text-2xl md:text-7xl text-[#005c7b]">
                Order Placed!
              </h2>
              {cart()}
              <p className="max-w-lg text-center">
                We appreciate your business! If you have any questions, please email
                at{" "}
                <a
                  href="mailto:contact@justnaturalpaws.org"
                  className="text-blue-500 underline hover:text-blue-700"
                >
                  contact@justnaturalpaws.org
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
