import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";

const fontStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
};

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userContact, setUserContact] = useState("");
  const [userAddress, setUserAddress] = useState({});
  const [totalWithTaxAmount, setTotalWithTaxAmount] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");

  useEffect(() => {
    if (!stripe || !props.clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe, props.clientSecret]);

  const calculateTaxAmountBody = (cartItems, address) => {
    let newObj = {
      cart: cartItems.map((item) => ({
        amount: Math.round(item.price * item.quantity * 100), // Stripe only accepts int numbers
        reference: item.name,
      })),
    };
    newObj["address"] = address;
    return newObj;
  };

  const updatePaymentIntentBody = (paymentIntentID) => {
    const obj = {};
    obj["payment_intent_id"] = paymentIntentID;
    obj["amount"] = Math.round(totalWithTaxAmount * 100);
    return obj;
  };

  const callCalculateTaxAPI = (address) =>
    setTimeout(async () => {
      fetch("/api/cart/calculate-tax", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(calculateTaxAmountBody(props.cartItems, address)),
      })
        .then(async (r) => {
          const response = await r.json();
          // retrieving total amount after taxes from the tax API
          const amountTotal = response?.calculation?.amount_total / 100;
          const taxAmount = response?.calculation?.tax_amount_exclusive / 100;
          setTotalWithTaxAmount(amountTotal);
          setTaxPercentage(taxAmount);
        })
        .catch((e) => {
          console.error(e.message);
          setMessage(
            "Something went wrong! Please try again by refreshing the page!"
          );
        });
    }, 500); // debounce API call by 0.5 sec to limit API calls to back-end

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("stripe or elements unavailable");
      return;
    }

    setIsLoading(true);
    let updateIntentSuccess = false;

    // update payment intent for new amount with tax added
    try {
      const r = await fetch("/api/update-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatePaymentIntentBody(props.paymentIntentID)),
      });

      if (!r.ok) throw new Error("Failed to update payment intent!");
      await r.json();
      updateIntentSuccess = true;
    } catch (e) {
      console.log(e.message);
      setMessage("Error while loading payment page! Please refresh the page.");
      setIsLoading(false);
      return;
    }

    if (updateIntentSuccess) {
      try {
        // Confirm the payment
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/OrderPage`,
            shipping: {
              phone: userContact,
            },
            payment_method_data: {
              billing_details: {
                address: userAddress,
                email: userEmail,
                phone: userContact,
              },
            },
          },
        });

        if (error) {
          console.error("Error:", error);
          setMessage(
            "There was a problem processing your payment. Please check your payment details or use different payment method!"
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error.message);
        setMessage(
          "There was a problem processing your payment. Please check your payment details or use different payment method!"
        );
        setIsLoading(false);
      }
    }
  };

  const calculateOrderAmount = () => {
    // Replace this constant with a calculation of the order's amount
    // Calculate the order total on the server to prevent
    // people from directly manipulating the amount on the client
    return props.cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };
  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto">
      {/* <div className="mb-4">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          placeholder="Name of Card Holder"
          required
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div> */}
      <div className="mb-4" style={fontStyle}>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          onChange={(e) => setUserEmail(e.target.value)}
        />
      </div>
      {/* <div className="mb-4">
        <label>Address:</label>
        <input
          type="text"
          name="address1"
          placeholder="Address"
          required
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div> */}
      {/* <div className="mb-4">
        <label>City:</label>
        <input
          type="text"
          name="city"
          placeholder="City"
          required
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div> */}
      {/* <div className="mb-4">
        <label>Province:</label>
        <input
          type="text"
          name="province"
          placeholder="Province"
          required
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div> */}
      <AddressElement
        options={{ mode: "shipping", allowedCountries: ["CA"] }}
        onChange={(event) => {
          if (event.complete) {
            setUserAddress(event.value.address);
            callCalculateTaxAPI(event.value.address);
          }
        }}
      />
      <div className="mt-2 mb-4" style={fontStyle}>
        <label>Contact No:</label>
        <input
          type="tel"
          pattern="[0-9]{10}"
          name="contact"
          placeholder="Contact No"
          required
          minLength={10}
          maxLength={10}
          className="w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          onChange={(e) => setUserContact(e.target.value)}
        />
      </div>
      <PaymentElement
        options={{
          allowedCountries: ["CA"],
          fields: {
            billingDetails: {
              address: "never",
            },
          },
        }}
      />
      <div className="flex flex-col mt-8">
        <span className="ml-6 mr-0 text-lg">
          Subtotal:
          <span className="ml-auto mr-5 text-lg">
            {" "}
            {calculateOrderAmount().toLocaleString("en-CA", {
              style: "currency",
              currency: "CAD",
            })}
          </span>
        </span>
        <span className="mt-2 ml-6 mr-0 text-lg">
          Taxes:
          <span className="ml-auto mr-5 text-lg">
            {" "}
            {taxPercentage.toLocaleString("en-CA", {
              style: "currency",
              currency: "CAD",
            })}
          </span>
        </span>
        <span className="mt-2 ml-6 mr-0 text-lg font-semibold">
          Total:
          <span className="ml-auto mr-5 text-lg">
            {" "}
            {totalWithTaxAmount.toLocaleString("en-CA", {
              style: "currency",
              currency: "CAD",
            })}
          </span>
        </span>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          disabled={isLoading || !stripe || !elements}
          className="w-full md:w-2/3 ml-auto mr-0 px-4 py-6 my-6 text-white bg-[#005c7b] rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          {isLoading ? "Processing..." : "Pay Now 🔒"}
        </button>
      </div>
      {message && <p className="text-red-600">{message}</p>}
    </form>
  );
};

export default PaymentForm;
