import React from 'react';

const PrivacyList = (props) => {
  return (
    <>
        <ol className='pl-6 mb-10 list-decimal'>
            {props.list.map((term, index) => (
                <li key={index} className='mb-2'>{term}</li>
            ))}
        </ol>
    </>
  )
}

export default PrivacyList;