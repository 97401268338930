import React, {useState} from "react";
import ReviewCard from "../components/ReviewSection/ReviewCard";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";

const AddReview = () => {
    const [reviewData, setReviewData] = useState({
      id: null,
      image: "",
      pet_name: "",
      rating: null,
      review_text: "",
    });
    const handleInputChange = (e) => {
      const { id, value, type, files } = e.target;
      const newValue = type === "file" ? files[0] : value.replace(/\r?\n/g, "\n");
      setReviewData({
        ...reviewData,
        [id]: newValue,
      });
    };

    const uploadReview = async (e) => {
      e.preventDefault();

      const confirmed = window.confirm("Are you sure you want to submit the review to the database?");

      if (!confirmed) {
        return; // User canceled the submission
      }

      try{
        const storage = getStorage();

        const imageRef = ref(storage, `ReviewImages/${reviewData.pet_name}Review`);
        await uploadBytes(imageRef, reviewData.image);

        const imageUrl = await getDownloadURL(imageRef);

        // Save product data to Firestore
        const reviewRef = collection(db, "review");
        const querySnapshot = await getDocs(reviewRef);
        const newId = querySnapshot.size + 1;
  
        const review = {
          id: newId,
          image: imageUrl,
          pet_name: reviewData.pet_name,
          rating: reviewData.rating,
          review_text: reviewData.review_text,
        };
  
        await addDoc(reviewRef, review);
  
        window.alert("Review added to Firestore successfully.");
      } catch (error) {
        window.alert("Error adding review to Firestore: " + error.message);
      }
    }
  return (
    <div className="flex-col justify-center mx-8 my-16 max-w-7xl md:mx-auto">
        <div className="flex justify-center">
          <h1 className="text-4xl font-semibold md:text-5xl">
            Add New Review
          </h1>
        </div>
        <div>
          <h2 className="mt-10 text-2xl font-semibold text-center md:text-3xl">
            Create a review card
          </h2>
          <form className="flex-col mx-auto my-16 w-[500px]" onSubmit={uploadReview}>
            <div className="flex items-center justify-between my-8">
              <label>Pet Name:</label>
              <input
                maxLength={30}
                onChange={handleInputChange}
                type="text"
                id="pet_name"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in review card (30 Max) characters"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Review:</label>
              <textarea
                onChange={handleInputChange}
                maxLength={220}
                type="text"
                id="review_text"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in review card (150 Max) characters"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Rating:</label>
              <input
                onChange={handleInputChange}
                type="number"
                step="0"
                min="0"
                max="5"
                id="rating"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="Rating 0-5"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Main Image:</label>
              <input
                onChange={handleInputChange}
                type="file"
                id="image"
                accept="image/*"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                required
              />
            </div>
            <button className="flex w-32 p-2 mx-auto mt-20 border rounded-lg"
              type="submit">
              Submit to DB
            </button>
          </form>
          <div className="flex justify-center h-[508px]">
            {reviewData.image &&
                <ReviewCard data={{data: {...reviewData, image: URL.createObjectURL(reviewData.image)}}} />
            }
          </div>
        </div>
    </div>
  )
}

export default AddReview;