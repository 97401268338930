import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <React.Fragment>
      <div className="h-screen flex flex-col justify-center items-center">
        <h1 className="text-4xl font-bold text-red-500">Oops!</h1>
        <p className="text-xl mt-5">Something Went Wrong</p>
        <Link
          to="/"
          className="bg-[#005c7b] text-xs font-bold text-white 
                    px-16 py-3 my-4 ml-4 rounded
                    transition-transform transform hover:scale-105 
                    hover:bg-gray-800 md:text-base"
        >
          Go Home
        </Link>
      </div>
    </React.Fragment>
  );
}
