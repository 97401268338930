import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
    const {image, shortTitle, description, id} = props.blog;
    const [isHovered, setIsHovered] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const handleTouch = (event) => {
            const cardElement = cardRef.current;
            if(cardElement && cardElement.contains(event.target)){
                setIsHovered(true);
            } else {
                setIsHovered(false);
            }
        }

        document.addEventListener('touchstart', handleTouch);
        return () => {
            document.removeEventListener('touchstart', handleTouch);
        }
    }, []);

  return (
    <div className='mx-auto my-4 shadow-lg rounded-lg w-80
    transition-transform transform hover:scale-105 hover:bg-[#005c7b] hover:text-white
    md:mx-4'
    ref={cardRef}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}>
        <div className='flex overflow-hidden rounded-t-lg h-80'>
            <img src={image} alt='name' className='object-cover h-full mx-auto'/>
        </div>
        <div className='px-8 py-4 rounded-b-lg'>
            <div>
                    <h4 className='my-4 text-lg font-semibold'>
                        {shortTitle}
                    </h4>
                    <p className='h-24 text-[12.4px]'>
                        {description}
                    </p>
            </div>
            <div className='flex'>
                <Link to={`/Blog?blogId=${id}`} className={`text-xs font-semibold my-4
                transition-transform transform hover:scale-105 md:text-base 
                ${isHovered ? 'text-white' : 'text-[#005c7b]'}`}>
                    Read more
                </Link>
            </div>
        </div>
    </div>
  )
}

export default BlogCard;