import React, { useState } from 'react';
import EditReview from './EditReview';
import EditProduct from './EditProduct';
import EditBlog from './EditBlog';

const Edit = () => {
  const [selectedComponent, setSelectedComponent] = useState('');

  const handleComponentChange = (e) => {
    setSelectedComponent(e.target.value);
  };

  return (
    <div className='flex justify-center my-10'>
      <div className='flex-col'>
        <h2 className='my-10 text-5xl font-bold text-center'>Select Component to Edit</h2>
        <select className='flex mx-auto text-xl' value={selectedComponent} onChange={handleComponentChange}>
          <option value="">Select an option</option>
          <option value="review">Edit Review</option>
          <option value="product">Edit Product</option>
          <option value="blog">Edit Blog</option>
        </select>
        
        {selectedComponent === 'review' && <EditReview />}
        {selectedComponent === 'product' && <EditProduct />}
        {selectedComponent === 'blog' && <EditBlog />}
      </div>
    </div>
  );
};

export default Edit;