import React, {useEffect, useState} from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {BlogLayout, BlogSection} from '../components';
import {FaArrowLeft, FaLinkedinIn, FaFacebookF, FaTwitter} from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

const BlogPage = () => {
    const [queryParameters] = useSearchParams();
    const blogId = queryParameters.get('blogId');
    const [blog, setBlog] = useState();
    const [content, setContent] = useState([]);
    useEffect(() => {
      window.scrollTo(0,0);
      const fetchData = async () => {
          try {
            const blogsCollection = collection(db, "blogs");
            const blogQuery = query(blogsCollection, where('id', '==', parseInt(blogId)));
            const blogSnapShot = await getDocs(blogQuery);

            if(!blogSnapShot.empty){
              const blogDocument = blogSnapShot.docs[0];
              const contentCollection = collection(blogDocument.ref, 'content');
              const contentSnapshot = await getDocs(contentCollection);
              const contentData = contentSnapshot.docs.map((doc) => doc.data());

              setBlog(blogDocument.data());
              contentData.sort((a,b) => a.id - b.id);
              setContent(contentData);
            }
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
      };
      
      fetchData();
    }, [blogId]);
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    }
    const currentUrl = 'https://justnaturalpaws.org/';//window.location.href; //use this link in deployment
  return (
    <>
      <div className='flex mx-10 mt-20 md:relative'>
        <div className='mx-auto w-full max-w-[950px]'>
          <button onClick={back} className='flex text-[#005c7b]'><FaArrowLeft/></button>
        </div>
      </div>
      <div className='flex mx-10 my-8'>
        <div className='mx-auto max-w-[950px]'>
          <div className='flex flex-col justify-between max-w-[950px] md:flex-row'>
          {blog && (
          <>
            <h1 className='leading-[50px] font-bold max-w-3xl mx-auto text-4xl lg:mx-0 md:leading-[70px] md:text-5xl'>{blog.longTitle}</h1>
            <div className='flex mt-10 space-x-4 md:mb-2 md:mt-auto'>
              <LinkedinShareButton 
              url={currentUrl}
              title={blog.longTitle}>
                <div className='p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105'>
                  <FaLinkedinIn color='#005c7b' size={20}/>
                </div>
              </LinkedinShareButton>
              <FacebookShareButton 
              url={currentUrl}
              hashtag='#justnaturalpaws'>
                <div className='p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105'>
                  <FaFacebookF color='#005c7b' size={20}/>
                </div>
              </FacebookShareButton>
              <TwitterShareButton 
              url={currentUrl}
              hashtags={['#justnaturalpaws']}>
                <div className='p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105'>
                  <FaTwitter color='#005c7b' size={20}/>
                </div>
              </TwitterShareButton>
            </div>
          </>)}
          </div>
          <div className='justify-between xl:flex xl:flex-wrap'>
            {content.map((section, index) => (
              <BlogLayout key={index} section={section}/>
            ))}
          </div>
        </div>
      </div>
      <div className='mx-auto flex mt-20 max-w-[950px]'>
        <div className='mx-auto lg:mx-0'>
          <h3 className='text-xl font-medium'>Read more</h3>
        </div>
      </div>
      <BlogSection count={3} currentBlog={blogId} />
    </>
  )
}

export default BlogPage;