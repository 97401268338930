import React, {useState, useEffect} from 'react';
import { BlogCard, BlogLayout } from "../components";
import {
    FaArrowLeft,
    FaLinkedinIn,
    FaFacebookF,
    FaTwitter,
  } from "react-icons/fa";
  import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";
  import { db } from "../firebase";
  import {
    collection,
    getDocs,
    doc,
    setDoc,
    updateDoc,
    query,
    orderBy,
    getDoc,
  } from "firebase/firestore";
  import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const EditBlog = () => {
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [blogCardData, setBlogCardData] = useState({
        data:{
        shortTitle: "",
        longTitle: "",
        image: null,
        description: "",
        }
    });
    
    const [blogContent, setBlogContent] = useState([]);
    const [blogContentData, setBlogContentData] = useState({
        format: "",
        heading: "",
        image: null,
        content: "",
    });

    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const blogRef = collection(db, "blogs");
            const querySnapshot = await getDocs(blogRef);
            const blogsData = [];
            querySnapshot.forEach((doc) => {
                blogsData.push({id: doc.id, data: doc.data()});
            });
            setBlogs(blogsData);
          } catch (error) {
            console.error("Error fetching blogs:", error);
          }
        };
    
        fetchBlogs();
    }, []);

    useEffect(() => {
        if(selectedBlog) {
            const fetchBlogContent = async () => {
                try {
                    const contentRef = collection(db, 'blogs', selectedBlog.id, "content");
                    const contentOrder = query(contentRef, orderBy("id"));
                    const querySnapshot = await getDocs(contentOrder);
                    const contentData = [];
                    querySnapshot.forEach((doc) => {
                        contentData.push(doc.data());
                    });
                    setBlogContent(contentData);
                } catch (error) {
                    console.error("Error fetching blog content:", error);
                }
            };
            fetchBlogContent();
        }
    }, [selectedBlog]);

    const handleBlogSelection = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedBlogData = blogs[selectedIndex - 1];
        setSelectedBlog(selectedBlogData);
        setBlogCardData(selectedBlogData);
    }

    const handleCardInputChange = (e) => {
        const {id, value, type, files} = e.target;
        let newValue = value.replace(/\r?\n/g, "\n");
        setBlogCardData((prevData) => {
            if (type === "file") {
                const imageURL = URL.createObjectURL(files[0]);
                return {
                    ...prevData,
                    data:{
                        ...prevData.data,
                        [id]: imageURL,
                    }
                };
            } else {
                return {
                    ...prevData,
                    data:{
                        ...prevData.data,
                        [id]: newValue,
                    }
                };
            }
        });
    };

    // Placeholder function to handle changes in blog content input fields
    const handleBlogInputChange = (e) => {
        const {id, value, type, files} = e.target;
        let newValue = value.replace(/\r?\n/g, "\n");
        setBlogContentData((prevData) => {
            if (type === "file") {
                const imageURL = URL.createObjectURL(files[0]);
                return {
                    ...prevData,
                    [id]: imageURL,
                };
            } else {
                return {
                    ...prevData,
                    [id]: newValue,
                };
            }
        });
    };

    const handleFormatChange = (e) => {
        setBlogContentData({
            ...blogContentData,
            format: e.target.value
        });
    };

    const handleDeleteSection = (indexToDelete) => {
        const updatedBlogContent = [...blogContent];
        updatedBlogContent.splice(indexToDelete, 1);
        setBlogContent(updatedBlogContent);
    }

    const handleBuildBlog = (e) => {
        e.preventDefault();
        let newContent = {};
        if (blogContentData.format === "img/content") {
          newContent = {
            format: blogContentData.format,
            image: blogContentData.image,
            content: blogContentData.content,
          };
        } else if (blogContentData.format === "standard") {
          newContent = {
            format: blogContentData.format,
            heading: blogContentData.heading,
            content: blogContentData.content,
          };
        } else if (blogContentData.format === "heading") {
          newContent = {
            format: blogContentData.format,
            heading: blogContentData.heading,
          };
        } else if (blogContentData.format === "card") {
          newContent = {
            format: blogContentData.format,
            content: blogContentData.content,
          };
        } else if (blogContentData.format === "option") {
          newContent = {
            format: blogContentData.format,
            heading: blogContentData.heading,
          };
        } else if (blogContentData.format === "optionCard") {
          newContent = {
            format: blogContentData.format,
            content: blogContentData.content,
          };
        }
        if(Object.values(newContent).some(Boolean)){
            setBlogContent([...blogContent, newContent]);
            setBlogContentData({
            format: "",
            heading: "",
            image: null,
            content: "",
            });
        }
      };

      const docExists = async (docRef) => {
        try{
            const docSnapshot = await getDoc(docRef);
            return docSnapshot.exists();
        } catch (error) {
            console.error("error", error);
            return false;
        }
      }

      const updateBlog = async (e) => {
        e.preventDefault();

        if (!selectedBlog) {
            window.alert("Please select a blog to update.");
            return;
        }
        const confirmed = window.confirm("Are you sure you want to update this blog?");
        if(!confirmed){
            return;
        }
        try {
            const storage = getStorage();
            const blogRef = doc(db, "blogs", selectedBlog.id);

            // Upload new image if there is one
            if (blogCardData.data.image instanceof File) {
                const response = await fetch(blogCardData.data.image);
                const blob = await response.blob();
                const storageRef = ref(storage, `BlogImages/${blogCardData.data.shortTitle}`);
                await uploadBytes(storageRef, blob);
                const imageURL = await getDownloadURL(storageRef);

                await updateDoc(blogRef, {
                    shortTitle: blogCardData.data.shortTitle,
                    longTitle: blogCardData.data.longTitle,
                    image: imageURL,
                    description: blogCardData.data.description,
                });
            } else {
                await updateDoc(blogRef, {
                    shortTitle: blogCardData.data.shortTitle,
                    longTitle: blogCardData.data.longTitle,
                    description: blogCardData.data.description,
                });
            }
    
            const contentRef = collection(blogRef, "content");
            for (let i = 0; i < blogContent.length; i++) {
                if (blogContent[i].image && blogContent[i].image.startsWith('blob:http')) {
                    const response = await fetch(blogContent[i].image);
                    const blob = await response.blob();
                    const contentImageRef = ref(storage, `BlogImages/Blog${blogCardData.data.id}Img${i + 1}`);
                    await uploadBytes(contentImageRef, blob);
                    blogContent[i].image = await getDownloadURL(contentImageRef);
                }
            
                const newContentRef = doc(contentRef, (i + 1).toString());

                if(await docExists(newContentRef)){
            
                    await updateDoc(newContentRef, {
                        format: blogContent[i].format,
                        heading: blogContent[i].heading || null,
                        image: blogContent[i].image || null,
                        content: blogContent[i].content || null,
                    });
                } else {
                    await setDoc(newContentRef, {
                        id: (i + 1),
                        format: blogContent[i].format,
                        heading: blogContent[i].heading || null,
                        image: blogContent[i].image || null,
                        content: blogContent[i].content || null,
                    })
                }
            }

            window.alert("Blog updated successfully!");
        } catch (error) {
            console.error("Error updating blog:", error);
        }
    };

  return (
    <React.Fragment>
        <div className="flex-col justify-center mx-8 my-16 max-w-7xl md:mx-auto">
            <div className="flex justify-center">
                <h1 className="text-4xl font-semibold md:text-5xl">
                Edit Blog
                </h1>
            </div>
            <form id="productForm" className="flex-col mx-auto my-16 w-[500px]">
                <div className="flex items-center justify-between my-8">
                    <label>Select Blog:</label>
                    <select
                        onChange={handleBlogSelection}
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                        defaultValue=""
                    >
                        <option value="" disabled hidden>
                        Choose a blog
                        </option>
                        {blogs.map((blog) => (
                        <option key={blog.data.id} value={blog.data.id}>
                            {blog.data.shortTitle}
                        </option>
                        ))}
                    </select>
                </div>
            </form>
        </div>
        {selectedBlog && (
        <div>
            <div>
                <form id="blogForm" className="flex-col mx-auto my-16 w-[500px]">
                    <div className="flex items-center justify-between my-8">
                    <label>Short title:</label>
                    <input
                        maxLength={27}
                        onChange={handleCardInputChange}
                        type="text"
                        id="shortTitle"
                        value={blogCardData.data.shortTitle}
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="This appears in blog card"
                        required
                    />
                    </div>
                    <div className="flex items-center justify-between my-8">
                    <label>Long title:</label>
                    <input
                        onChange={handleCardInputChange}
                        type="text"
                        id="longTitle"
                        value={blogCardData.data.longTitle}
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="This appears at top of blog page"
                        required
                    />
                    </div>
                    <div className="flex items-center justify-between my-8">
                    <label>Main Image:</label>
                    <input
                        onChange={handleCardInputChange}
                        type="file"
                        id="image"
                        accept="image/*"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        required
                    />
                    </div>
                    <div className="flex items-center justify-between my-8">
                    <label>Description:</label>
                    <textarea
                        onChange={handleCardInputChange}
                        minLength={170}
                        maxLength={205}
                        type="text"
                        id="description"
                        value={blogCardData.data.description}
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="This appears in blog card (170-205) characters"
                        required
                    />
                    </div>
                </form>
            </div>
            {blogCardData.data.image !== null && (
            <div className="flex-col mx-auto w-fit">
                <h3 className="text-2xl font-semibold text-center md:text-3xl">
                Blog Card Sample
                </h3>
                <BlogCard
                blog={blogCardData.data}
                />
            </div>
            )}
            <div>
            {blogCardData.data.longTitle && blogCardData.data.image ? (
                <>
                <div className="flex mx-10 mt-20 md:relative">
                    <div className="mx-auto w-full max-w-[950px]">
                    <button className="flex text-[#005c7b]">
                        <FaArrowLeft />
                    </button>
                    </div>
                </div>
                <div className="flex mx-10 mt-8 mb-20">
                    <div className="mx-auto max-w-[950px]">
                        <div className="flex flex-col justify-between w-[950px] md:flex-row">
                            <h1 className="leading-[50px] font-bold max-w-3xl mx-auto text-4xl lg:mx-0 md:leading-[70px] md:text-5xl">
                            {blogCardData.data.longTitle}
                            </h1>
                            <div className="flex mt-10 space-x-4 md:mb-2 md:mt-auto">
                            <LinkedinShareButton title={blogCardData.data.longTitle}>
                                <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                                <FaLinkedinIn color="#005c7b" size={20} />
                                </div>
                            </LinkedinShareButton>
                            <FacebookShareButton hashtag="#justnaturalpaws">
                                <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                                <FaFacebookF color="#005c7b" size={20} />
                                </div>
                            </FacebookShareButton>
                            <TwitterShareButton hashtags={["#justnaturalpaws"]}>
                                <div className="p-2 border-[1px] border-[#005c7b]/25 rounded-full transform hover:scale-105">
                                <FaTwitter color="#005c7b" size={20} />
                                </div>
                            </TwitterShareButton>
                            </div>
                        </div>
                        <div className="justify-between xl:flex xl:flex-wrap">
                            {blogContent &&
                            blogContent.map((section, index) =>
                                <BlogLayout key={index} section={section} />
                            )}
                            <BlogLayout section={blogContentData} />
                        </div>
                    </div>
                </div>
                </>
            ) : null}
            </div>
            <div>
            <h3 className="text-3xl font-semibold text-center">
                Add/Delete content
            </h3>
            <form className="flex-col mx-auto my-16 w-[500px]">
                <div className="flex items-center justify-between my-8">
                <label htmlFor="format">Format:</label>
                <select
                    id="format"
                    name="format"
                    value={blogContentData.format}
                    onChange={handleFormatChange}
                    required
                >
                    <option value="">Select an Option</option>
                    <option value="img/content">Image with Content</option>
                    <option value="standard">Heading and Content</option>
                    <option value="heading">Large Card Heading</option>
                    <option value="card">Large Card</option>
                    <option value="option">Small Card Heading</option>
                    <option value="optionCard">Small Card</option>
                </select>
                </div>
                {blogContentData.format === "img/content" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Content Image:</label>
                    <input
                        onChange={handleBlogInputChange}
                        type="file"
                        id="image"
                        accept="image/*"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        required
                    />
                    </div>
                    <div className="flex items-center justify-between my-8">
                    <label>Content:</label>
                    <textarea
                        onChange={handleBlogInputChange}
                        type="text"
                        id="content"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter content"
                        required
                    />
                    </div>
                </>
                )}
                {blogContentData.format === "standard" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Heading:</label>
                    <input
                        onChange={handleBlogInputChange}
                        type="text"
                        id="heading"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter heading"
                        required
                    />
                    </div>
                    <div className="flex items-center justify-between my-8">
                    <label>Content:</label>
                    <textarea
                        onChange={handleBlogInputChange}
                        type="text"
                        id="content"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter content"
                        required
                    />
                    </div>
                </>
                )}
                {blogContentData.format === "heading" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Heading:</label>
                    <input
                        onChange={handleBlogInputChange}
                        type="text"
                        id="heading"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter heading"
                        required
                    />
                    </div>
                </>
                )}
                {blogContentData.format === "card" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Content:</label>
                    <textarea
                        onChange={handleBlogInputChange}
                        type="text"
                        id="content"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter content"
                        required
                    />
                    </div>
                </>
                )}
                {blogContentData.format === "option" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Heading:</label>
                    <input
                        onChange={handleBlogInputChange}
                        type="text"
                        id="heading"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter heading"
                        required
                    />
                    </div>
                </>
                )}
                {blogContentData.format === "optionCard" && (
                <>
                    <div className="flex items-center justify-between my-8">
                    <label>Content:</label>
                    <textarea
                        onChange={handleBlogInputChange}
                        type="text"
                        id="content"
                        className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                            focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                            block p-2.5"
                        placeholder="Enter content"
                        required
                    />
                    </div>
                </>
                )}
                <div className="flex items-center my-8">
                <button
                    onClick={handleBuildBlog}
                    type="submit"
                    className="bg-[#005c7b] rounded-lg px-4 py-2 mx-auto"
                >
                    Add Content
                </button>
                </div>
            </form>
            <button
                className="flex px-4 py-2 mx-auto bg-red-500 rounded-lg"
                onClick={() => handleDeleteSection(blogContent.length - 1)}
                >Delete Last Section
            </button>
            <button className="flex p-2 mx-auto mt-20 border rounded-lg" type="submit" form="blogForm" onClick={updateBlog}>
                Submit to DB
            </button>
            </div>
        </div>
        )}
    </React.Fragment>
  )
}

export default EditBlog;