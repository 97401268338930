import React, {useState, useEffect} from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, updateProductItem } from '../../actions/productActions';
import { updateCartItem } from '../../actions/cartActions';

const QuantityButton = (props) => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);

    const cartItem = cartItems.find((item) => item.id === props.product.id);

    const [displayedQuantity, setDisplayedQuantity] = useState(props.product.quantity);

    const increment = (e) => {
        e.preventDefault();
        dispatch(incrementQuantity(props.product.id));
        if (cartItem) {
            dispatch(updateCartItem({ id: cartItem.id, quantity: cartItem.quantity + 1 }));
        }
        setDisplayedQuantity(displayedQuantity + 1);
    }

    const decrement = (e) => {
        e.preventDefault();
        dispatch(decrementQuantity(props.product.id));
        if (displayedQuantity > 0) {
            setDisplayedQuantity(displayedQuantity - 1);
            if (cartItem) {
                dispatch(updateCartItem({ id: cartItem.id, quantity: cartItem.quantity - 1 }));
            }
        }
    }

    useEffect(() => {
        dispatch(updateProductItem(props.product.id, props.product.quantity));
        setDisplayedQuantity(cartItem ? cartItem.quantity : props.product.quantity);
    }, [props.product.quantity, dispatch, props.product.id, cartItem]);
  return (
    <div className={props.cartPopup ? 'flex items-center justify-center w-20 h-6 px-2 my-auto border-2 border-[#005c7b] rounded space-x-3' : 
    props.productCard ? 'flex items-center justify-center justify-between w-72 h-[40px] px-4 my-4 border-2 border-[#005c7b] rounded md:h-[50px]' :
    'flex items-center justify-center justify-between w-32 h-8 px-4 my-auto border-2 border-[#005c7b] rounded md:w-48 md:h-[50px]'}>
        <button className={props.cartPopup ? 'text-xs text-[#005c7b] md:hover:scale-125' : 
        'md:text-xl text-[#005c7b] md:hover:scale-125'} onClick={(e) => decrement(e)}><FaMinus/></button>
        <h4 className={props.cartPopup ? 'text-xs' : 
        'font-semibold md:text-xl'}>{displayedQuantity}</h4>
        <button className={props.cartPopup ? 'text-xs text-[#005c7b] md:hover:scale-125' : 
        'md:text-xl text-[#005c7b] md:hover:scale-125'} onClick={(e) => increment(e)}><FaPlus/></button>
    </div>
  )
}

export default QuantityButton;