import React, {useEffect} from "react";
import { BlogSection, 
  GetToKnowSection, 
  GridSection, 
  Hero, 
  ProductSection, 
  ReviewSection, 
  TikTokSection,
  SubscriptionPopup } from "../components";

function Home() {
  useEffect(() => {
    window.scrollTo(0,0);
  },[]);
  return (
    <div>
      <SubscriptionPopup />
      <Hero />
      <ProductSection subHeading={false}/>
      <GetToKnowSection />
      <GridSection />
      <ReviewSection />
      <BlogSection title={true} count={3} />
      <TikTokSection />
    </div>
  );
}

export default Home;