import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

export const ContactUsReport = () => {
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ContactUsInfo = query(
          collection(db, "contact"),
          orderBy("createdAt", "desc") // Assuming 'timestamp' is your timestamp field
        );

        const querySnapshot = await getDocs(ContactUsInfo);

        querySnapshot.forEach((doc) => {
          setContactData((preState) => [...preState, doc.data()]);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="pt-10 px-10">
        <h1 className="text-center text-3xl font-extrabold text-gray-900 mb-8">
          Customer Contact Info
        </h1>
        <div className="flex items-center justify-center mt-20">
          {/* Center the table in the middle of the page */}

          <div className="bg-white shadow-md rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    First Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Last Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Email address
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Message
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Date of Request
                  </th>
                </tr>
              </thead>
              <tbody>
                {contactData.length > 0 ? (
                  contactData.map((item) => {
                    return (
                      <tr key={item.createdAt}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.firstName}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.lastName}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.email}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.phoneNumber}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.message}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item.createdAt.toDate().toDateString()}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Data sdsdfsd
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Data dsfsddfs
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Data sfsf
                    </td>
                  </tr>
                )}

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
