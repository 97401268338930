import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
// import GetToKnowUs from "../../images/GetToKnowUs.jpg";
// import GetToKnowBG from "../../images/GetToKnowBG.png";

const GetToKnowSection = () => {
  return (
    <div className="flex flex-col items-center justify-center m-4 w-7/8">
      <div className="p-8 max-w-[1100px] md:flex">
        <div className="flex items-center justify-center w-full mb-8 md:w-1/2 md:pr-14">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FGetToKnowUs.jpg?alt=media&token=6ee0dbff-11d4-41ea-ae3e-bd80dff211d9"
            }
            alt="img1"
            className="w-full rounded-xl"
          />
        </div>
        <div className="justify-center md:flex md:flex-col md:w-1/2 md:pl-4">
          <h2 className="relative text-4xl font-semibold md:text-5xl">
            Get to know us
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FGetToKnowBG.png?alt=media&token=b6817a31-ec23-4c8e-8d1e-d49089bbed19"
              }
              alt="bg"
              className="absolute right-0 hidden w-60 h-36 -bottom-6 xl:block"
            />
          </h2>
          <p className="leading-8 my-8 max-w-[470px] md:text-medium">
            We're not just a pet treat company; we're here for pet wellness and
            health, globally. We're here to make our loved ones happy, to
            promote their health and well being, and to donate a share of our
            profits to animal welfare efforts globally - which makes everybody
            happy.
          </p>
          <Link
            to="/About"
            className="flex items-center justify-center w-fit 
          font-bold text-xs text-left text-[#005c7b]
          transition-transform transform hover:scale-105
          md:text-base"
          >
            Read More &nbsp;&nbsp;
            <FaArrowRight size={28} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetToKnowSection;
