import React from 'react';
import CartRow from '../CartRow/CartRow';

const ShoppingCartPopup = () => {
  return (
    <div className="fixed flex items-center justify-center text-xl text-black bg-white rounded-lg shadow-[0_0_15px_5px_rgba(0,0,0,0.3)] bottom-20 right-5 md:bottom-40 md:right-20">
        <CartRow popup={true} />
    </div>
  )
}

export default ShoppingCartPopup