import React from 'react'

const PrivacyStandard = (props) => {
  return (
    <div>
        <h4 className='mx-auto text-xl font-bold md:text-2xl'>{props.heading}</h4>
        <p className='mt-6 mb-10'>
            {props.text}
        </p>
    </div>
  )
}

export default PrivacyStandard