import React, { useEffect, useState } from "react";
// import ContactImg from "../../images/ContactImg.jpg";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { sendEmail, sendEmailAdmin } from "../EmailResponse/sendEmail";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhoneNumber, setErrorMessagePhoneNumber] = useState("");
  const [sucessMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    // Basic email format validation using regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const onChangeForm = (e) => {
    switch (e.target.id) {
      case "first_name":
        setFormData({ ...formData, firstName: e.target.value });
        break;
      case "last_name":
        setFormData({ ...formData, lastName: e.target.value });
        break;

      case "email":
        setFormData({ ...formData, email: e.target.value });
        if (e.target.value && !validateEmail(e.target.value)) {
          setErrorMessageEmail("Please enter a valid email address.");
        } else {
          setErrorMessageEmail("");
        }

        break;

      case "phone_number":
        setFormData({ ...formData, phoneNumber: e.target.value });
        if (e.target.value && !validatePhoneNumber(e.target.value)) {
          setErrorMessagePhoneNumber("Please enter a valid  phone number.");
        } else {
          setErrorMessagePhoneNumber("");
        }
        break;

      case "message":
        setFormData({ ...formData, message: e.target.value });
        break;

      default:
        break;
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.message
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    } else {
      setErrorMessage("");
    }

    if (!validateEmail(formData.email)) {
      setErrorMessageEmail("Please enter a valid email address.");
      return;
    }

    if (!validatePhoneNumber(formData.phoneNumber)) {
      setErrorMessageEmail("Please enter a valid phone number.");
      return;
    }

    const dataToSubmit = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
      createdAt: Timestamp.now(),
    };
    try {
      // const docRef = await db.collection("contact").add(dataToSubmit);
      const q = query(
        collection(db, "contact"),
        where("email", "==", dataToSubmit.email),
        orderBy("createdAt", "desc"), // Assuming 'timestamp' is your timestamp field
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      let checkRecentDetails = [];
      querySnapshot.forEach((doc) => {
        checkRecentDetails.push(doc.data());
      });

      if (checkRecentDetails.length > 0) {
        if (
          checkRecentDetails[0].createdAt.toMillis() + 24 * 60 * 30 * 1000 <
          Date.now()
        ) {
          const docRef = await addDoc(collection(db, "contact"), dataToSubmit);
          setSuccessMessage("Thanks for contacting us!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
          sendEmail(formData);
          sendEmailAdmin(formData);
        } else {
          setSuccessMessage("We are working on your request!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } else {
        const docRef = await addDoc(collection(db, "contact"), dataToSubmit);
        setSuccessMessage("Thanks for contacting us!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
        sendEmail(formData);
        sendEmailAdmin(formData);
      }

      // if(checkRecentDetails.createdAt.toDate( ){

      // }

      // const docRef = await addDoc(collection(db, "contact"), dataToSubmit);
      // setSuccessMessage(true);
      // setTimeout(() => {
      //   setSuccessMessage(false);
      // }, 5000);
      // sendEmail(formData);

      // console.log(`Data written with ID: ${docRef.id}`);
    } catch (err) {
      console.log(`${err}`);
    }
  };

  return (
    <>
    <div className="justify-center max-w-6xl px-10 mx-auto my-10 md:space-x-10 md:flex md:flex-wrap">
      <div className="flex mx-auto mb-10 lg:mb-0 justify-center items-center w-full md:w-[420px] max-w-sm">
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FContactImg.jpg?alt=media&token=e6e6658d-200b-4574-9c93-713adacbf1a2"
          }
          alt="img1"
          className="w-full max-h-full rounded-xl"
        />
      </div>
      <div className="mx-auto md:w-[500px]">
        <div className="flex-col justify-center items-start gap-2.5 inline-flex">
          <h1 className="text-center text-zinc-900 text-3xl font-extrabold leading-[78.40px] md:text-5xl">
            Get in Touch
          </h1>
          <p className="max-w-[425px] mb-4 text-zinc-600 text-lg font-normal leading-relaxed">
            Have any question or feedback, feel free to reach out to us. We are
            always available to help.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label id="lblFirstName" className="block mb-2 text-sm font-medium">
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
              block w-full p-2.5"
              placeholder="John"
              required
              onChange={onChangeForm}
              value={formData.firstName}
              onFocus={() => {
                const label = document.getElementById("lblFirstName");
                label.classList.toggle("text-[#005c7b]");
              }}
              onBlur={() => {
                const label = document.getElementById("lblFirstName");
                label.classList.toggle("text-[#005c7b]");
              }}
            />
          </div>
          <div>
            <label id="lblLastName" className="block mb-2 text-sm font-medium">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
              block w-full p-2.5"
              placeholder="kennedy"
              required
              onChange={onChangeForm}
              value={formData.lastName}
              onFocus={() => {
                const label = document.getElementById("lblLastName");
                label.classList.toggle("text-[#005c7b]");
              }}
              onBlur={() => {
                const label = document.getElementById("lblLastName");
                label.classList.toggle("text-[#005c7b]");
              }}
            />
          </div>
          <div>
            <label id="lblEmail" className="block mb-2 text-sm font-medium">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="John_kennedy@gmail.com"
              required
              onChange={onChangeForm}
              value={formData.email}
              className={
                errorMessageEmail && !validateEmail(formData.email)
                  ? "border-red-500 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none focus:border-red-500 focus:ring-1 focus:ring-red-500 block w-full p-2.5"
                  : `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block w-full p-2.5`
              }
              onFocus={() => {
                const label = document.getElementById("lblEmail");
                label.classList.toggle("text-[#005c7b]");
              }}
              onBlur={() => {
                const label = document.getElementById("lblEmail");
                label.classList.toggle("text-[#005c7b]");
              }}
            />
            {errorMessageEmail && !validateEmail(formData.email) && (
              <p className="text-xs italic text-red-500">{errorMessageEmail}</p>
            )}
          </div>
          <div>
            <label id="lblPhone" className="block mb-2 text-sm font-medium">
              Phone Number
            </label>
            <input
              type="number"
              id="phone_number"
              placeholder="4169903155"
              required
              onChange={onChangeForm}
              value={formData.phoneNumber}
              className={
                errorMessagePhoneNumber &&
                !validatePhoneNumber(formData.phoneNumber)
                  ? "border-red-500 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:outline-none focus:border-red-500 focus:ring-1 focus:ring-red-500 block w-full p-2.5"
                  : `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block w-full p-2.5`
              }
              onFocus={() => {
                const label = document.getElementById("lblPhone");
                label.classList.toggle("text-[#005c7b]");
              }}
              onBlur={() => {
                const label = document.getElementById("lblPhone");
                label.classList.toggle("text-[#005c7b]");
              }}
            />
            {errorMessagePhoneNumber &&
              !validatePhoneNumber(formData.phoneNumber) && (
                <p className="text-xs italic text-red-500">
                  {errorMessagePhoneNumber}
                </p>
              )}
          </div>

          <div className="col-span-2 ...">
            <div>
              <label id="lblMessage" className="block mb-2 text-sm font-medium">
                Message
              </label>
              <textarea
                type="text"
                id="message"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                block w-full p-2.5"
                placeholder="John"
                required
                onChange={onChangeForm}
                rows="5"
                value={formData.message}
                maxLength="2000" // Set maximum length
                onFocus={() => {
                  const label = document.getElementById("lblMessage");
                  label.classList.toggle("text-[#005c7b]");
                }}
                onBlur={() => {
                  const label = document.getElementById("lblMessage");
                  label.classList.toggle("text-[#005c7b]");
                }}
              />
              <p className="float-right">
                {
                  formData.message.split(/\s+/).filter((word) => word !== "")
                    .length
                }
                /2000
              </p>
            </div>
          </div>
          <div>
            {errorMessage && (
              <p className="text-sm italic text-red-500">{errorMessage}</p>
            )}
            <button
              className="w-44 py-[10px] text-white text-sm font-normal bg-[#005c7b] rounded-[3px]"
              onClick={onSubmitForm}
            >
              Send message
            </button>
          </div>
        </div>
        {sucessMessage !== "" ? (
          <div
            className="relative px-4 py-3 mt-2 text-green-700 bg-green-100 border border-green-400 rounded w-fit"
            role="alert"
          >
            <strong class="font-bold">{sucessMessage}</strong>
            {/* <span class="block sm:inline">will get back to you soon</span> */}
            {/* <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg class="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
              </span> */}
          </div>
        ) : null}
      </div>
    </div>
    <h2 className="px-10 mt-20 mb-8 text-4xl font-bold text-center">Are you a retailer?</h2>
    <div className="flex justify-center">
      <iframe title="Are you a retailer?" src="https://www.faire.com/embed/bw_2x4ww6qtej" 
      width="900" height="600"></iframe>
    </div>
    </>
  );
}

export default Contact;
