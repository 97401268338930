import axios from "axios";

export function sendEmail(data) {
  console.log(data);
  axios
    .post("https://emailsender-3sxvekekgq-uc.a.run.app", {
      email: data.email,
      name: data.firstName,
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function sendEmailAdmin(data) {
  console.log(data);
  axios
    .post("https://emailsenderadmin-3sxvekekgq-uc.a.run.app", {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      message: data.message,
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function sendEmailAdminCoupon(email) {
  console.debug(email);
  const url = `https://emailsenderadmincoupon-3sxvekekgq-uc.a.run.app?email=${email}`;
  axios
    .get(url)
    .then(function (response) {
      console.debug(response);
    })
    .catch(function (error) {
      console.error(error);
    });
}
