import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsCart, BsCartCheck } from "react-icons/bs";
import ShoppingCartPopup from "../ShoppingCartPopup/ShoppingCartPopup";
import { useLocation } from "react-router-dom";

function ShoppingCart() {
  const [currentPage, setCurrentPage] = useState("");
  const [hidden, setHidden] = useState(true);
  const [cartIcon, setCartIcon] = useState(null);
  const cartItems = useSelector((state) => state.cart.items);
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  useEffect(() => {
    // Hide the shopping cart whenever the user navigates to a new page
    setHidden(true);
  }, [location.pathname]);

  const hide = currentPage === "/CheckoutPage" || currentPage === "/OrderPage";

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartIcon(<BsCartCheck />);
    } else {
      setCartIcon(<BsCart />);
      setHidden(true);
    }
  }, [cartItems]);

  const handleHidden = () => {
    if (cartItems.length > 0) {
      hidden ? setHidden(false) : setHidden(true);
    }
  };

  if (hide) {
    return null;
  }

  return (
    <React.Fragment>
      <button
        onClick={handleHidden}
        className="fixed flex items-center justify-center text-xl text-white bg-[#005c7b] rounded-full cursor-pointer bottom-5 right-5 md:bottom-20 md:right-20 w-14 h-14 hover:scale-105"
      >
        {cartIcon}
        <div className="absolute flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full text-[10px] bottom-9 left-7">
          {cartItems.length}
        </div>
      </button>
      <div className={hidden ? "hidden" : ""}>
        <ShoppingCartPopup />
      </div>
    </React.Fragment>
  );
}

export default ShoppingCart;
