import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  Timestamp,
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { sendEmailAdminCoupon } from "../EmailResponse/sendEmail";

const SubscriptionPopup = () => {
  const [email, setEmail] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  const getCookie = (name) => {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  };

  useEffect(() => {
    const popupClosed = getCookie('popupClosed');
    if(popupClosed === 'true'){
      setIsPopupVisible(false);
    }
  },[]);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value && !validateEmail(e.target.value)) {
      setErrorMessageEmail("Please enter a valid email address.");
    } else {
      setErrorMessageEmail("");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if(validateEmail(email)){
      const subscriber = {
        email: email,
        createdAt: Timestamp.now(),
      }
      try{
        const q = query(collection(db, "subscription"), where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          const docRef = await addDoc(
            collection(db, "subscription"),
            subscriber
          );
          sendEmailAdminCoupon(subscriber.email);
        } else {
          querySnapshot.forEach(async (doc) => {
            const data = doc.data();
            if (data.createdAt.toMillis() + 24 * 60 * 30 * 1000 < Date.now()) {
              const docRef = await addDoc(
                collection(db, "subscription"),
                subscriber
              );
              sendEmailAdminCoupon(subscriber.email);
            }
          });
        }

        console.log('Email submitted:', email);
        setIsPopupVisible(false);
      } catch(err){
        console.log(err);
      }
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setCookie('popupClosed', 'true', 30);
  };

  return isPopupVisible ? (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-4 bg-[#E1F5FC] rounded-[30px] w-[450px] h-[600px]">
        <div className='absolute top-4 right-6'>
          <button className="text-[#005c7b] text-xl" onClick={closePopup}>
            &#10005;
          </button>
        </div>
        <div className="flex justify-center px-32 my-4">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FLogo2.webp?alt=media&token=fc971687-0644-4981-a1e5-f37936d57a98"
            alt="logo"
          />
        </div>
        <div className="flex-col font-serif">
          <h2 className="mx-auto text-[50px] lg:text-[55px] text-[#005c7b] font-bold w-fit"
          style={{
            fontFamily: 'fields, Georgia, Times, "Times New Roman", serif',
          }}>Get 15% OFF!</h2>
          <p className="mt-4 mb-2 mx-auto text-center text-lg max-w-[370px]"
          style={{
            fontFamily: 'fields, Georgia, Times, "Times New Roman", serif',
          }}>
            Save 15% on your first purchase when you subscribe to the <strong>#justnaturalpaws</strong> newsletter!
          </p>
          <form>
            <input
              type="email"
              className="w-full p-2 text-center border border-gray-400 rounded-xl"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            {errorMessageEmail && !validateEmail(email) && (
              <p className="-mb-2 text-xs italic text-center text-red-500">{errorMessageEmail}</p>
            )}
            <button
              type="button"
              className="w-full px-4 py-2 mt-2 text-white bg-[#005c7b] rounded-xl hover:bg-gray-800"
              onClick={handleSignup}
            >
              Sign Up
            </button>
          </form>
        </div>
        <img src="https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2Fpopup.jpeg?alt=media&token=a03923a2-586f-42d0-897e-01c088f8982a" 
            alt="popup"
            className="absolute right-0 bottom-0 rounded-b-[30px]"/>
      </div>
    </div>
  ) : null;
};

export default SubscriptionPopup;