import React from 'react';
import StarRating from './StarRating';

const ReviewCard = (props) => {
    const {pet_name,rating,review_text,image} = props.data.data;
  return (
    <div className='border-[1px] border-slate-200 my-8 shadow-lg rounded-lg w-56
    transition-transform transform hover:scale-[102%] hover:bg-[#005c7b] hover:text-white z-50'>
        <div className='w-40 h-40 mx-auto -mt-8 overflow-hidden rounded-full'>
            <img src={image} alt='name' className='object-cover h-full'/>
        </div>
        <div className='p-4'>
            <div className='flex'>
                <div className='mx-auto'>
                    <StarRating rating={rating}/>
                    <h4 className='my-4 text-lg font-semibold text-center'>{pet_name}</h4>
                    <p className='mx-auto mb-2 text-sm text-center w-44'>
                        {review_text}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReviewCard;