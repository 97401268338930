import React, { useEffect, useState, useRef } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import ReviewCard from "./ReviewCard";
// import ReviewHighlight from "../../images/ReviewHighlight.jpg";

const ReviewSection = () => {
  const reviewContainerRef = useRef(null);

  const reviewCardWidth = 240;

  const scrollLeft = () => {
    if (reviewContainerRef.current) {
      reviewContainerRef.current.scrollBy({
        left: -reviewCardWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (reviewContainerRef.current) {
      reviewContainerRef.current.scrollBy({
        left: reviewCardWidth,
        behavior: "smooth",
      });
    }
  };

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "review"), orderBy("id", "asc"));
    onSnapshot(q, (querySnapshot) => {
      setReviews(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  return (
    <div>
      <div className="flex items-center justify-center mx-8 mb-12 space-x-2">
        <h2 className="text-4xl font-semibold text-center md:text-5xl">
          What our furry friends say
        </h2>
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FReviewHighlight.jpg?alt=media&token=557c67b6-3198-4156-ba00-61cfb04110a3"
          }
          alt="bg"
          className="hidden w-20 h-20 -mt-4 lg:block"
        />
      </div>
      <div
        className="flex m-auto py-4 max-w-[224px] md:max-w-[465px] lg:max-w-[945px] overflow-x-auto"
        ref={reviewContainerRef}
      >
        <div className="flex space-x-4">
          {reviews.map((review, index) => (
            <ReviewCard key={index} data={review} />
          ))}
        </div>
      </div>
      <div className="flex justify-center m-6">
        <button
          className="border-[1px] border-slate-200 m-2 p-2 w-10 rounded-full hover:bg-[#005c7b] hover:text-white transition duration-300"
          onClick={scrollLeft}
        >
          &larr;
        </button>
        <button
          className="border-[1px] border-slate-200 m-2 p-2 w-10 rounded-full hover:bg-[#005c7b] hover:text-white transition duration-300"
          onClick={scrollRight}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default ReviewSection;
