import React from "react";
import { Link } from "react-router-dom";

export default function AdminHome() {
  return (
    <React.Fragment>
      <div className="container justify-center mx-auto mt-10 h-52">
        <div className="flex flex-wrap justify-center gap-10 ">
          <Link to="/Admin/AddProduct">
            <div className="relative group transition-transform transform scale-100 hover:scale-110 hover:bg-[#005c7b]  hover:text-white hover:font-bold bg-white rounded-lg shadow-md p-8 border border-gray-300">
              Add new product
            </div>
          </Link>
          <Link to="/Admin/AddBlog">
            <div className="relative group transition-transform transform scale-100 hover:scale-110 hover:bg-[#005c7b]  hover:text-white hover:font-bold bg-white rounded-lg shadow-md p-8 border border-gray-300">
              Add new blog
            </div>
          </Link>
          <Link to="/Admin/ContactUsReport">
            <div className="relative group transition-transform transform scale-100 hover:scale-110 hover:bg-[#005c7b]  hover:text-white hover:font-bold bg-white rounded-lg shadow-md p-8 border border-gray-300">
              Contact Report
            </div>
          </Link>
          <Link to="/Admin/AddReview">
            <div className="relative group transition-transform transform scale-100 hover:scale-110 hover:bg-[#005c7b]  hover:text-white hover:font-bold bg-white rounded-lg shadow-md p-8 border border-gray-300">
              Add new review
            </div>
          </Link>
          <Link to="/Admin/Edit">
            <div className="relative group transition-transform transform scale-100 hover:scale-110 hover:bg-[#005c7b]  hover:text-white hover:font-bold bg-white rounded-lg shadow-md p-8 border border-gray-300">
              Edit
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
