import React from 'react';

const BlogLayout = ({section}) => {
  const content = section.content && section.content.replace(/\\n/g, '\n');
  return (
    <>
      {section.format === 'img/content' &&
        <div className='w-full'>
          <img src={section.image} alt={section.id} className='w-full mx-auto my-14 h-92 rounded-2xl' />
          <p className='whitespace-pre-line mx-auto max-w-[740px] md:text-[16.8px] lg:mx-0'>
            {content}
          </p>
        </div>
      }
      {section.format === 'standard' &&
        <div className='max-w-[740px] mx-auto lg:mx-0'>
          <h2 className='font-semibold mt-10 mb-2 text-3xl text-3xl md:leading-[60px] md:text-[45px]'>{section.heading}</h2>
          <p className='whitespace-pre-line mb-8 md:text-[16.8px]'>
            {content}
          </p>
        </div>
      }
      {section.format === 'heading' &&
        <div className='w-full'>
          <h2 className='max-w-[740px] mx-auto lg:mx-0 font-semibold mt-10 text-3xl md:text-[45px]'>{section.heading}</h2>
        </div>
      }
      {section.format === 'card' &&
        <div className='border-2 border-slate-200 shadow-xl flex my-8 mx-auto lg:mx-0 h-[240px] rounded-2xl max-w-[300px] sm:max-w-[475px]'>
          <div className='px-16 mx-auto my-auto lg:mx-0'>
            <p className='text-xs whitespace-pre-line md:text-sm'>
              {content}
            </p>
          </div>
        </div>
      }
      {section.format === 'option' &&
        <div className='mx-auto max-w-[740px] lg:mx-0 xl:w-[370px]'>
          <h1 className='font-semibold mx-auto lg:mx-0 mt-10 text-3xl md:leading-[60px] md:text-[45px]'>{section.heading}</h1>
        </div>
      }
      {section.format === 'optionCard' &&
        <div className='max-w-[740px] mx-auto lg:mx-0'>
          <div className='shadow-xl flex mt-6 xl:mt-16 w-[264px] mx-auto h-[220px] rounded-2xl lg:mx-0 xl:ml-4'>
            <div className='p-7'>
              <h2 className='mb-2 text-lg font-semibold'>{section.heading}</h2>
              <p className='whitespace-pre-line'>
                {content}
              </p>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default BlogLayout;