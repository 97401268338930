import React from 'react';
import GridInfo from './GridInfo';
import { commitments } from './Commitments';

const GridSection = () => {
  return (
    <div className='mx-auto my-20'>
        <h2 className='text-4xl font-semibold text-center md:text-5xl'>
            Our commitment
        </h2>
        <p className='text-center mx-auto p-8 md:max-w-[400px]'>
            Just healthy - just tasty - from our family to yours!
        </p>
        <div className='flex flex-wrap justify-center px-8 mx-auto md:max-w-6xl'>
          {commitments.map((data) => (
            <GridInfo key={data.index} data={data} />
          ))}
        </div>
    </div>
  )
}

export default GridSection