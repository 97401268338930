import React, {useState} from "react";
import ProductCard from "../components/ProductCard/ProductCard";
import { ImageCarousel } from "../components";
import { ProductPageInfo } from "../components";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";
import { FaArrowLeft } from "react-icons/fa";
import {CartButton} from "../components";


function AddProduct() {
  const [fieldIndices, setFieldIndices] = useState([0]);
  const [imageIndices, setImageIndices] = useState([0]);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    id: null,
    images: [""],
    buyLink: "www.justnaturalpaws.com",
    infoFields: [],
    infoHeadings: [],
    longDescription: "",
    price: null,
  });
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const newValue = value.replace(/\r?\n/g, "\n");
    setProductData({
      ...productData,
      [id]: newValue,
    });
  };

  const handleImageSelect = (e, index) => {
    const newImages = [...productData.images];
    const file = e.target.files[0];
    if(file){
      newImages[index] = file;
      setProductData({
        ...productData,
        images: newImages,
      });
    }
  };

  // Update infoHeadings and infoFields based on index
  const handleInfoInputChange = (e, index, isHeading) => {
    const newInfoHeadings = [...productData.infoHeadings];
    const newInfoFields = [...productData.infoFields];
  
    if (isHeading) {
      newInfoHeadings[index] = e.target.value;
    } else {
      newInfoFields[index] = e.target.value.replace(/\r?\n/g, "\n");
    }
  
    setProductData({
      ...productData,
      infoHeadings: newInfoHeadings,
      infoFields: newInfoFields,
    });
  };

  const handleAddField = () => {
    setFieldIndices((prevIndices) => [...prevIndices, prevIndices.length]);
    setProductData((prevData) => ({
      ...prevData,
      infoHeadings: [...prevData.infoHeadings, ""],
      infoFields: [...prevData.infoFields, ""],
    }));
  };

  const handleDeleteLastField = () => {
    if (fieldIndices.length > 0) {
      setFieldIndices((prevIndices) => {
        const newIndices = [...prevIndices];
        newIndices.pop(); // Remove the last index
        return newIndices;
      });
      setProductData((prevData) => {
        const newInfoHeadings = [...prevData.infoHeadings];
        newInfoHeadings.pop(); // Remove the last infoHeading
        const newInfoFields = [...prevData.infoFields];
        newInfoFields.pop(); // Remove the last infoField
        return {
          ...prevData,
          infoHeadings: newInfoHeadings,
          infoFields: newInfoFields,
        };
      });
    }
  };

  const handleAddImage = () => {
    setProductData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ""],
    }));
    setImageIndices((prevIndices) => [...prevIndices, prevIndices.length]);
  };

  const handleDeleteLastImage = () => {
    if (imageIndices.length > 1) {
      setProductData((prevData) => {
        const newImages = [...prevData.images];
        newImages.pop(); // Remove the last image
        return {
          ...prevData,
          images: newImages,
        };
      });
      setImageIndices((prevIndices) => {
        const newIndices = [...prevIndices];
        newIndices.pop(); // Remove the last index
        return newIndices;
      });
    }
  };

  const uploadProductData = async (e) => {
    e.preventDefault();

    if (
      !productData.name ||
      !productData.description ||
      productData.images[5] === "" ||
      !productData.buyLink ||
      !productData.price ||
      !productData.longDescription
    ) {
      window.alert("Please fill in all required fields before submitting.");
      return;
    }

    const confirmed = window.confirm("Are you sure you want to submit the product to the database?");

    if (!confirmed) {
      return; // User canceled the submission
    }

    try {
      const storage = getStorage();
      const imageRefs = [];

      // Upload images to Firebase Storage and collect their references
      for (let i = 0; i < productData.images.length; i++) {
        const imageFile = productData.images[i];
        if (imageFile) {
          const imageRef = ref(storage, `ProductImages/${productData.name}_Image${i + 1}`);
          await uploadBytes(imageRef, imageFile);
          imageRefs.push(await getDownloadURL(imageRef));
        }
      }

      // Save product data to Firestore
      const productsRef = collection(db, "product");
      const querySnapshot = await getDocs(productsRef);
      const newId = querySnapshot.size + 1;

      const product = {
        id: newId,
        name: productData.name,
        description: productData.description,
        images: imageRefs,
        buyLink: productData.buyLink,
        infoHeadings: productData.infoHeadings,
        infoFields: productData.infoFields,
        longDescription: productData.longDescription,
        price: productData.price,
      };

      await addDoc(productsRef, product);

      window.alert("Product added to Firestore successfully.");
      } catch (error) {
        window.alert("Error adding product to Firestore: " + error.message);
      }
  };
  return (
    <React.Fragment>
      <div className="flex-col justify-center mx-8 my-16 max-w-7xl md:mx-auto">
        <div className="flex justify-center">
          <h1 className="text-4xl font-semibold md:text-5xl">
            Add New Product
          </h1>
        </div>
        <div>
          <h2 className="mt-10 text-2xl font-semibold text-center md:text-3xl">
            Create product card and enter product images
          </h2>
          <form id="productForm" className="flex-col mx-auto my-16 w-[500px]" onSubmit={uploadProductData}>
            <div className="flex items-center justify-between my-8">
              <label>Title:</label>
              <input
                maxLength={50}
                onChange={handleInputChange}
                type="text"
                id="name"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in product card (50 Max) characters"
                required
              />
            </div>
            <div className="flex-col items-center my-8">
              {productData.images.map((image, index) => (
                <div className="flex items-center justify-between my-8" key={index}>
                {index > 0 ? <label>Image {index}:</label>:<label>Card Image:</label>}
                <input
                  onChange={(e) => handleImageSelect(e, index)}
                  type="file"
                  key={index}
                  accept="image/*"
                  className="w-92 ml-auto mr-0 my-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                      focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                      block p-2.5"
                  required
                />
                <img className="w-10 mx-2" src={image instanceof File && URL.createObjectURL(image)} alt="test img" />
                </div>
              ))}
              <div className="flex items-center my-8">
                <button
                  type="button"
                  onClick={handleAddImage}
                  className="bg-[#005c7b] text-white rounded-lg px-4 py-2 mx-auto"
                >
                  Add Image
                </button>
                <button
                  type="button"
                  onClick={handleDeleteLastImage}
                  className="px-4 py-2 mx-auto text-white bg-red-500 rounded-lg"
                >
                  Delete Last Image
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Description:</label>
              <textarea
                onChange={handleInputChange}
                maxLength={105}
                type="text"
                id="description"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="This appears in product card (105 Max) characters"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Buy Now Link:</label>
              <input
                onChange={handleInputChange}
                type="text"
                id="buyLink"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                    focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                    block p-2.5"
                placeholder="Insert Amazon Link"
                required
              />
            </div>
          </form>
        </div>
        {productData.images[0] !== "" && (
          <div className="flex-col mx-auto w-fit">
            <h3 className="text-2xl font-semibold text-center md:text-3xl">
              Product Card Sample
            </h3>
            <ProductCard data={{...productData,
            images: productData.images.map((image) => image instanceof File && URL.createObjectURL(image))}}/>
          </div>
        )}
        <div>
          <h3 className="mt-20 text-3xl font-semibold text-center">
            Add information to the product
          </h3>
          <form className="flex-col mx-auto my-16 w-[580px]">
            <div className="flex-col mx-auto my-16 w-[580px]">
                <div className="flex items-center justify-between my-8">
                  <label>Set Price:</label>
                  <input
                    onChange={handleInputChange}
                    type="number"
                    step="0.01"
                    min="0"
                    id="price"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter price (10.99)"
                    required
                  />
                </div>
                <div className="flex items-center justify-between my-8">
                  <label>Long Description:</label>
                  <textarea
                    onChange={handleInputChange}
                    type="text"
                    id="longDescription"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter Long Product Description - appears on product page"
                    required
                  />
                </div>
                {fieldIndices.length > 0 && fieldIndices.map((index) => (
                <>
                <div className="flex items-center justify-between my-8">
                  <label>Information Heading:</label>
                  <input
                    onChange={(e) => handleInfoInputChange(e, index, true)}
                    type="text"
                    id="infoHeadings"
                    value={productData.infoHeadings[index]}
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter dropdown heading"
                    required
                  />
                </div>
                <div className="flex items-center justify-between my-8">
                  <label>Information Content:</label>
                  <textarea
                    onChange={(e) => handleInfoInputChange(e, index, false)}
                    type="text"
                    value={productData.infoFields[index]}
                    id="infoFields"
                    className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                        block p-2.5"
                    placeholder="Enter dropdown content"
                    required
                  />
                </div>
                </>
              ))}
            <div className="flex items-center my-8">
              <button
              type="button"
                onClick={handleAddField}
                className="bg-[#005c7b] text-white rounded-lg px-4 py-2 mx-auto"
              >
                More Information Fields
              </button>
              <button
                type="button"
                onClick={() => handleDeleteLastField()}
                className="px-4 py-2 mx-auto text-white bg-red-500 rounded-lg"
              >
                Delete Last Information Field
              </button>
            </div>
            </div>
          </form>
        </div>
        {productData.images[1] && (
          <div className='flex flex-col items-center justify-center max-w-6xl mx-auto my-16 w-7/8'>
            <div className='mx-auto lg:px-20 xl:px-0'>
                <div className='pl-8 lg:relative lg:top-[24px]'>
                    <button className='flex w-fit text-[#005c7b]'><FaArrowLeft/></button>
                </div>
                <div className='flex flex-col-reverse px-8 lg:flex-row'>
                    <div className='justify-center lg:flex lg:flex-col lg:w-1/2 lg:pr-8'>
                        <h3 className='pt-14 text-3xl max-w-[500px] font-semibold lg:text-4xl'>
                            {productData.name}
                        </h3>
                        <p className='whitespace-pre-line text-sm leading-7 my-8 max-w-[345px]'>{productData.longDescription}</p>
                        <h5 className='my-auto text-xl font-semibold'>${productData.price}</h5>
                        <div className='flex my-4 space-x-3'>
                            <CartButton product={productData}/>
                            <a href={productData.buyLink}
                              target='_blank' rel='noreferrer'
                              className="border-2 border-[#005c7b] text-xs font-bold px-[20px] py-1.5 
                              md:text-center md:w-44 md:h-[50px] my-auto rounded 
                              transition-transform transform hover:scale-105 
                              md:text-base flex items-center justify-center">
                                Buy On Amazon
                            </a>
                        </div>
                    </div>
                    <div className='flex justify-center max-w-lg mt-6 md:mt-0 md:max-h-[500px] md:w-1/2'>
                        {productData && <ImageCarousel images={productData.images.slice(1)
                        .map((image) => (image instanceof File && URL.createObjectURL(image)))}/>}
                    </div>
                </div>
            </div>
            <div className='mt-20 ml-0 mr-auto md:px-20 xl:px-0'>
                {productData && productData.infoHeadings.map((info, index) => (
                    <ProductPageInfo key={index} heading={info} info={productData.infoFields[index]} />
                ))}
            </div>
            <button className="w-32 p-2 mt-32 border rounded-lg"
              type="submit" form="productForm">
              Submit to DB
            </button>
          </div>
          )}
      </div>
    </React.Fragment>
  );
}

export default AddProduct;
