import React from "react";

const GridInfo = (props) => {
  return (
    <div className='bg-slate-200 flex float-left m-4 p-4
    items-center rounded-[12px] h-52 w-full max-w-[505px]
    transition-transform transform hover:scale-105 hover:bg-[#005c7b] hover:text-white'>
        <div className='w-2/5 h-full overflow-hidden'>
            <img src={props.data.image} alt='product' className='object-cover h-full rounded-lg'/>
        </div>
        <div className='w-3/5 ml-5'>
            <h5 className='mb-4 text-sm font-semibold md:text-lg'>
                {props.data.heading}
            </h5>
            <p className='text-xs md:text-sm'>
                {props.data.description}
            </p>
        </div>
    </div>
  );
};

export default GridInfo;
