import React from 'react';
import {FaStar} from 'react-icons/fa';

const StarRating = ({rating}) => {
    const stars = 5;
    return (
        <div className="flex items-center justify-center space-x-2">
            {Array.from({ length: stars }, (_, index) => (
                <FaStar key={index}
                className={index < rating ? 'text-yellow-400' : 'text-gray-300'}/>
            ))}
        </div>
    )
}

export default StarRating;
