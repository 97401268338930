import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { CartButton, ImageCarousel, ProductPageInfo } from '../components';
import { useSelector } from 'react-redux';

const ProductPage = () => {
    const [queryParameters] = useSearchParams();
    const id = queryParameters.get('productId');
    const cartItems = useSelector((state) => state.cart.items);
    const cartItem = cartItems.find((item) => item.id.toString() === id);
    const initialQuantity = cartItem ? cartItem.quantity : 0;
    const products = useSelector((state) => state.products.products);
    const productData = useMemo(() => {
        const foundProduct = products.find((item) => item.id.toString() === id);
        return foundProduct ? { ...foundProduct, quantity: initialQuantity } : null;
    }, [id, products, initialQuantity]);

    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };

    // Initialize total state to 0
    const [total, setTotal] = useState(0);

    // Ensure productData and productData.quantity are defined before calculating total
    useEffect(() => {
        if (productData) {
            // Calculate and set the total
            setTotal(productData.price * productData.quantity);
        }
    }, [productData]);

    if (!productData) {
        return null;
    }

    const longDescription = productData.longDescription && productData.longDescription.replace(/\\n/g, '\n');

    return (
        <div className='flex flex-col items-center justify-center max-w-6xl mx-auto my-16 w-7/8'>
            <div className='mx-auto lg:px-20 xl:px-0'>
                <div className='pl-8 lg:relative lg:top-[24px]'>
                    <button onClick={back} className='flex w-fit text-[#005c7b]'><FaArrowLeft/></button>
                </div>
                <div className='flex flex-col-reverse px-8 lg:flex-row'>
                    <div className='justify-center lg:flex lg:flex-col lg:w-1/2 lg:pr-8'>
                        <h3 className='pt-14 text-3xl max-w-[500px] font-semibold lg:text-4xl'>
                            {productData.name}
                        </h3>
                        <p className='whitespace-pre-line text-sm leading-7 my-8 max-w-[345px]'>{longDescription}</p>
                        <h5 className='my-auto text-xl font-semibold'>${productData.price}</h5>
                        <div className='flex my-4 space-x-3'>
                            <CartButton product={productData}/>
                            <a href={productData.buyLink}
                               target='_blank' rel='noreferrer'
                               className="border-2 border-[#005c7b] text-xs font-bold px-[20px] py-1.5 
                               md:text-center md:w-44 md:h-[50px] my-auto rounded 
                               transition-transform transform hover:scale-105 
                               md:text-base flex items-center justify-center">
                                Buy On Amazon
                            </a>
                        </div>
                        <div className={cartItem ? 'mb-6' : 'hidden'}>
                            <h5 className='text-xl font-semibold'>Total: {total.toLocaleString("en-CA", {style:"currency", currency:"CAD"})}</h5>
                            <p>Minimum purchase of 5 total items.</p>
                        </div>
                    </div>
                    <div className='flex justify-center max-w-lg mt-6 lg:mt-0 lg:max-h-[500px] lg:w-1/2'>
                        {productData && <ImageCarousel images={productData.images.slice(1)}/>}
                    </div>
                </div>
            </div>
            <div className='mt-20 lg:ml-0 lg:mr-auto lg:px-20 xl:px-0'>
                {productData && productData.infoHeadings.map((info, index) => (
                    <ProductPageInfo key={index} heading={info} info={productData.infoFields[index]}/>
                ))}
            </div>
        </div>
    )
}

export default ProductPage;