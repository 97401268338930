import React from 'react';
import CartButton from '../CartButton/CartButton';

const ProductCard = (props) => {

    const {name,description,id,images} = props.data;
    
  return (
    <div className='mx-auto my-4 shadow-[0_0_7px_5px_rgba(0,0,0,0.1)] rounded-[20px] w-80 overflow-hidden 
    transition-transform transform md:hover:scale-105
    md:mx-4'>
        <a href={`/ProductPage?productId=${id}`}>
            <div className='h-80'>
                <img src={images[0]} alt='name' className='object-cover h-full'/>
            </div>
            <div className='p-4 bg-white rounded-b-lg'>
                <div className='flex justify-between'>
                    <div className='mx-auto max-w-[250px]'>
                        <h4 className='mb-4 text-lg font-semibold'>{name}</h4>
                        <p className='h-16 text-sm'>{description}</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <CartButton product={props.data} productCard={true} />
                </div>
            </div>
        </a>
    </div>
  )
}

export default ProductCard;