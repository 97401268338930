import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
// import logo from "../../images/Logo.jpg";

const Navbar = () => {
  const navigate = useNavigate();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [uid, setUid] = useState("");
  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  const onLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        localStorage.clear();
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        console.log("user is logged out login again");
      }
    });
  }, []);
  return (
    <>
      <nav className="sticky top-0 z-[1000] bg-white shadow-md">
        <div className="container flex items-center justify-between max-w-6xl px-12 py-2 mx-auto">
          <div className="flex items-center">
            <Link to="/">
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FLogo.jpg?alt=media&token=ae55f362-1a66-408f-ae9d-e40d75266773"
                }
                alt="Logo"
                className="h-20"
              />
            </Link>
          </div>
          <div
            id="mobileMenu"
            className={`${
              mobileMenuVisible ? "" : "hidden"
            } z-50 absolute top-20 left-0 right-0 bg-white shadow-md text-center
                md:block md:relative md:top-0 md:shadow-none`}
          >
            <ul className="md:flex md:space-x-10">
              <li className="mb-5 md:mb-0">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#005c7b] font-bold"
                      : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
                  }
                  onClick={toggleMobileMenu}
                >
                  Home
                </NavLink>
              </li>
              <li className="mb-5 md:mb-0">
                <NavLink
                  to="/Shop"
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#005c7b] font-bold"
                      : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
                  }
                  onClick={toggleMobileMenu}
                >
                  Shop
                </NavLink>
              </li>
              <li className="mb-5 md:mb-0">
                <NavLink
                  to="/About"
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#005c7b] font-bold"
                      : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
                  }
                  onClick={toggleMobileMenu}
                >
                  About
                </NavLink>
              </li>
              <li className="mb-5 md:mb-0">
                <NavLink
                  to="/Blogs"
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#005c7b] font-bold"
                      : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
                  }
                  onClick={toggleMobileMenu}
                >
                  Blog
                </NavLink>
              </li>
            </ul>
          </div>
          <div
            id="mobileMenu"
            className={`${
              mobileMenuVisible ? "flex items-center" : "hidden"
            } z-50 absolute top-60 left-0 right-0 bg-white shadow-md text-center
                md:block md:relative md:top-0 md:shadow-none`}
          >
            <NavLink
              to="/Contact"
              className="bg-[#005c7b] text-xs font-bold text-white 
                  px-16 py-3 my-4 mx-auto rounded
                  transition-transform transform hover:scale-105 
                  hover:bg-gray-800 md:text-base"
              onClick={toggleMobileMenu}
            >
              Contact
            </NavLink>
            {uid != "" && uid == localStorage.getItem("uid") ? (
              <NavLink
                className="bg-[#005c7b] text-xs font-bold text-white 
                    px-16 py-3 my-4 ml-4 rounded
                    transition-transform transform hover:scale-105 
                    hover:bg-gray-800 md:text-base"
                onClick={onLogout}
              >
                Logout
              </NavLink>
            ) : null}
          </div>
          <div className="flex items-center md:hidden">
            {/* <button>
              <a href="/Cart">
                <img
                  src={cart}
                  alt="Cart"
                  className="w-6 h-6 transition duration-300 transform hover:scale-125"
                />
              </a>
            </button>
            <button className="ml-4 ">
              <a href="/profile">
                <img
                  src={profile}
                  alt="user"
                  className="w-6 h-6 transition duration-300 transform hover:scale-125"
                />
              </a>
            </button> */}
            <button
              id="mobileMenuToggle"
              className="ml-4 md:hidden"
              onClick={toggleMobileMenu}
            >
              <div className="space-y-2">
                <span className="block w-6 h-px bg-black"></span>
                <span className="block w-6 h-px bg-black"></span>
                <span className="block w-6 h-px bg-black"></span>
              </div>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
