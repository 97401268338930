import React, {useEffect} from 'react';
import { PrivacyStandard, PrivacyList } from '../components';

const PolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[]);
    const privacyData = [
        {
            heading: 'Information Collection and Use',
            text: `We may collect personal information 
            such as your name, email address, and shipping 
            address when you place an order or subscribe to 
            our newsletter. This information is used solely 
            for the purpose of fulfilling your orders and 
            communicating with you about our products and services.`
        },
        {
            heading: 'Data Security',
            text: `We take reasonable measures to protect your personal 
            information from unauthorized access, disclosure, alteration, 
            or destruction. However, please be aware that no data 
            transmission over the internet or method of electronic storage 
            can be guaranteed to be 100% secure.`
        },
        {
            heading: 'Third-Party Disclosure',
            text: `We do not sell, trade, or transfer your personal 
            information to third parties. However, we may share information 
            with trusted third parties who assist us in operating our 
            website, conducting business, or servicing you.`
        },
        {
            heading: 'Cookies',
            text: `Our website may use cookies to enhance your browsing 
            experience. These cookies may collect non-personally 
            identifiable information about your visit and help us analyze 
            website traffic.`
        },
        {
            heading: 'Terms of Use',
            text: `By accessing and using www.justnaturalpaws.org, 
            you agree to comply with the following terms and conditions:`
        }
    ]
    const terms = [
        `The content on our website is provided for informational purposes 
        only. We make no guarantees about the accuracy, completeness, or 
        suitability of the information.`,
        `You may not reproduce, distribute, modify, transmit, or otherwise 
        use any content from our website without our prior written consent.`,
        `We reserve the right to modify or discontinue our products, services, 
        and website at any time without notice.`,
        `You agree to use our website only for lawful purposes and in a way 
        that does not infringe on the rights of others.`
    ]
    const contact = {
        heading: 'Contact Us',
        text: `If you have any questions about our Privacy Policy 
        or Terms of Use, please contact us at contact@justnaturalpaws.org.`
    }
  return (
    <div className='mx-10 my-16 max-w-[950px] lg:mx-auto'>
        <div className='w-full mx-auto'>
            <div className='max-w-[715px]'>
                <h1 className='mx-auto text-4xl font-bold md:text-5xl'>Privacy Policy</h1>
                <p className='my-10'>
                    This Privacy Policy outlines how we collect, use, and protect the information you 
                    provide on www.Justnaturalpaws.org. By using our website, you consent to the 
                    practices described herein.
                </p>
                {privacyData.map((data, index) => (
                    <PrivacyStandard key={index} heading={data.heading} text={data.text}/>
                ))}
                <PrivacyList list={terms} />
                <PrivacyStandard heading={contact.heading} text={contact.text} />
            </div>
        </div>
    </div>
  )
}

export default PolicyPage;