import React from 'react';
import { useDispatch } from 'react-redux';
import { removeFromCart } from '../../actions/cartActions';
import { updateProductItem } from '../../actions/productActions';
import { FaTrash } from 'react-icons/fa';

function RemoveButton({product}){
    const dispatch = useDispatch();
    const handleRemove = () => {
        dispatch(removeFromCart(product));
        dispatch(updateProductItem(product.id, 0));
    }
    return (
        <button onClick={handleRemove}>
            <FaTrash className='text-[#005c7b]'/> 
        </button>
    );
}

export default RemoveButton;