import React from 'react'

const AboutInfoSection = (props) => {
  return (
    <div className="flex flex-col items-center justify-center m-10 w-7/8">
        <div className={`p-8 max-w-7xl justify-center justify-between md:flex ${props.reverse && 'md:flex-row-reverse'}`}>
          <div className={`justify-center max-w-[530px] md:flex md:flex-col md:w-1/2 ${props.reverse ? 'md:pl-10' : 'md:pr-10'}`}>
            <h2 className="font-semibold text-3xl max-w-[350px] md:text-4xl md:leading-[3rem]">
              {props.heading}
            </h2>
            <p className="my-8 text-base leading-8">
              {props.info}
            </p>
          </div>
          <div className={`mb-8 flex justify-center items-center w-full max-w-md md:w-1/2 ${props.reverse ? 'md:pr-10' : 'md:pl-10'}`}>
              <img src={props.image} alt='img1' className="w-full rounded-2xl"/>
          </div>
        </div>
    </div>
  )
}

export default AboutInfoSection;