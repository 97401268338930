export const commitments = [
  {
    index: 1,
    heading: "Our 1% Promise",
    description:
      "1% profit from every purchase is pledged toward animal welfare efforts globally.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic1.jpg?alt=media&token=60f61403-e4bb-4b72-9215-c2f593de4f8e",
  },
  {
    index: 2,
    heading: "Human Grade",
    description:
      "Human Grade: Yes, have a bite - just remember to leave some for your little one too!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic2.jpg?alt=media&token=6bb94892-935a-4d7a-843f-dfadd9ce6845",
  },
  {
    index: 3,
    heading: "Handcrafted",
    description:
      "Every single treat is formed and made with the utmost care, and yes, they are 100% handmade ensuring the best quality all the way from sourcing, to baking, and finally delivery.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FCatPic3.jpg?alt=media&token=7144f68e-652c-4de8-b797-de8cc067a214",
  },
  {
    index: 4,
    heading: "Superfood Trainers",
    description:
      "The treat that your pup loves, and so does their gut. And yes - we know that is a dream come true for all of us fellow pet parents. Just healthy and tasty - Just Natural Paws!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic4.jpg?alt=media&token=9de3b407-f626-4b72-aef7-917058278b0d",
  },
];
