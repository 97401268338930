import React, { useEffect } from "react";
import "./index.css";
import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Navbar, ShoppingCart } from "./components";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import BlogsPage from "./pages/BlogsPage";
import BlogPage from "./pages/BlogPage";
import ShopPage from "./pages/ShopPage";
import CheckoutPage from "./pages/CheckoutPage";
import OrderPage from "./pages/OrderPage";
import PolicyPage from "./pages/PolicyPage";

import ProtectAdmin from "./Auth/ProtectAdmin";
import { Login } from "./pages/Login";
import { ContactUsReport } from "./Admin/ContactUsReport";
import AddBlog from "./Admin/AddBlog";
import AddProduct from "./Admin/AddProduct";
import AddReview from "./Admin/AddReview";
import AdminHome from "./Admin/AdminHome";
import Edit from "./Admin/Edit";
import NotFound from "./PageNotFound/NotFound";
import { useDispatch } from "react-redux";
import { fetchProducts } from "./actions/productActions"

function App() {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Shop" element={<ShopPage />} />
        <Route path="/About" element={<About />} />
        <Route path="/Blogs" element={<BlogsPage />} />
        <Route path="/Blog" element={<BlogPage />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/ProductPage" element={<ProductPage />} />
        <Route path="/CheckoutPage" element={<CheckoutPage />} />
        <Route path="/OrderPage" element={<OrderPage />} />
        <Route path="/Privacy" element={<PolicyPage />} />
        {/* **Work in Progress** */}
        <Route
          path="/Admin/Home"
          element={
            <ProtectAdmin>
              <AdminHome />
            </ProtectAdmin>
          }
        />
        <Route
          path="/Admin/AddBlog"
          element={
            <ProtectAdmin>
              <AddBlog />
            </ProtectAdmin>
          }
        />
        <Route
          path="/Admin/ContactUsReport"
          element={
            <ProtectAdmin>
              <ContactUsReport />
            </ProtectAdmin>
          }
        />
        <Route
          path="/Admin/AddProduct"
          element={
            <ProtectAdmin>
              <AddProduct />
            </ProtectAdmin>
          }
        />
        <Route
        path="/Admin/AddReview"
        element={
          <ProtectAdmin>
            <AddReview />
          </ProtectAdmin>
        }
        />
        <Route
        path="/Admin/Edit"
        element={
          <ProtectAdmin>
            <Edit />
          </ProtectAdmin>
        }
        />
        <Route path="/Login" element={<Login />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>

      <Footer />
      <ShoppingCart/>
    </BrowserRouter>
  );
}

export default App;
