import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../actions/cartActions';
import QuantityButton from '../QuantityButton/QuantityButton';

function CartButton(props){
    const [inCart, setInCart] = useState('Puchase Now');
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);

    const isInCart = cartItems.some((item) => item.name === props.product.name);

    useEffect(() => {
        if(isInCart){
            setInCart(<QuantityButton product={props.product} productCard={props.productCard}/>);
        } else {
            setInCart(null);
        }
    }, [isInCart, props.product, props.productCard]);

    const handleAddToCart = (e) => {
        e.preventDefault();
        if(!isInCart){
            dispatch(addToCart(props.product));
            setInCart(<QuantityButton product={props.product} productCard={props.productCard}/>);
        }
    }
    
    return (
        <React.Fragment>
            {inCart ? inCart :
            <button className={props.productCard ? `bg-[#005c7b] text-xs font-bold text-white 
            w-full mx-4 my-4 py-3 mx-auto rounded
            transition-transform transform hover:scale-105 
            hover:bg-gray-800 md:text-base` : `flex bg-[#005c7b] text-xs font-bold text-white 
            justify-center md:w-[182px] px-6 py-2 md:py-3 my-auto rounded
            transition-transform transform hover:scale-105 
            hover:bg-gray-800 lg:text-base`} onClick={(e) => handleAddToCart(e)}>
                Purchase Now
            </button>}
        </React.Fragment>
    );
}

export default CartButton;