import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaLinkedinIn, FaTiktok } from "react-icons/fa";
// import logo from "../../images/Logo.jpg";

const Footer = () => {
  return (
    <footer className="max-w-6xl p-8 mx-auto md:w-5/6">
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex items-center">
          <div>
            <Link to="/">
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FLogo.jpg?alt=media&token=ae55f362-1a66-408f-ae9d-e40d75266773"
                }
                alt="Logo"
                className="h-20"
              />
            </Link>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href="https://www.linkedin.com/company/just-natural-paws/"
            rel="noreferrer"
            target="_blank"
            className="border-2 border-[#005c7b]/25 rounded-full p-2 text-[#005c7b] hover:scale-105"
          >
            <FaLinkedinIn size={24} />
          </a>
          <a
            href="https://www.tiktok.com/@justnaturalpaws"
            rel="noreferrer"
            target="_blank"
            className="border-2 border-[#005c7b]/25 rounded-full p-2 text-[#005c7b] hover:scale-105"
          >
            <FaTiktok size={24} />
          </a>
        </div>
      </div>

      <nav className="container flex justify-start mx-auto mt-10 mb-20">
        <ul className="flex space-x-4 md:space-x-10">
          <li className="mb-5 md:mb-0">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-[#005c7b] font-bold"
                  : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
              }
            >
              Home
            </NavLink>
          </li>
          <li className="mb-5 md:mb-0">
            <NavLink
              to="/Shop"
              className={({ isActive }) =>
                isActive
                  ? "text-[#005c7b] font-bold"
                  : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
              }
            >
              Shop
            </NavLink>
          </li>
          <li className="mb-5 md:mb-0">
            <NavLink
              to="/About"
              className={({ isActive }) =>
                isActive
                  ? "text-[#005c7b] font-bold"
                  : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
              }
            >
              About
            </NavLink>
          </li>
          <li className="mb-5 md:mb-0">
            <NavLink
              to="/Blogs"
              className={({ isActive }) =>
                isActive
                  ? "text-[#005c7b] font-bold"
                  : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
              }
            >
              Blog
            </NavLink>
          </li>
          <li className="mb-5 md:mb-0">
            <NavLink
              to="/Contact"
              className={({ isActive }) =>
                isActive
                  ? "text-[#005c7b] font-bold"
                  : "text-gray-600 hover:text-[#005c7b] hover:font-bold"
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>

      <hr className="border-black" />

      <div className="container flex items-center justify-between mx-auto my-10">
        <p className="text-[10px] sm:text-sm">
          &copy; {new Date().getFullYear()} Just Natural Paws. All rights
          reserved.
        </p>
        <div className="text-[10px] sm:text-sm">
          <NavLink to="/Privacy" className="hover:text-[#005c7b]">
            Privacy Policy
          </NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
