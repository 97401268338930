import React, { useState, useEffect } from "react";
import ReviewCard from "../components/ReviewSection/ReviewCard";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const EditReview = () => {
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [reviewData, setReviewData] = useState({
    id: null,
    image: "",
    pet_name: "",
    rating: null,
    review_text: "",
  });

  // Fetch reviews from Firestore
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewRef = collection(db, "review");
        const querySnapshot = await getDocs(reviewRef);
        const reviewsData = [];
        querySnapshot.forEach((doc) => {
          reviewsData.push({ id: doc.id, data: doc.data() });
        });
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    if(selectedReview){
        setReviewData(selectedReview);
    }
  }, [selectedReview]);

  // Handle selection of a review
  const handleReviewSelection = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedReviewData = reviews[selectedIndex - 1];
    setSelectedReview(selectedReviewData);
    setReviewData(selectedReviewData);
  };

  // Handle input changes in the form fields
  const handleInputChange = (e) => {
    const { id, value, type, files } = e.target;
    let newValue = value.replace(/\r?\n/g, "\n");
  
    // If the input type is a file (image), convert it to a URL
    if (type === "file") {
      newValue = files[0];
      const imageURL = URL.createObjectURL(files[0]);
  
      setReviewData({
        ...reviewData,
        [id]: imageURL,
      });
  
      if (selectedReview) {
        setSelectedReview({
          ...selectedReview,
          data: {
            ...selectedReview.data,
            [id]: imageURL,
          },
        });
      }
    } else {
      setReviewData({
        ...reviewData,
        [id]: newValue,
      });
  
      if (selectedReview) {
        setSelectedReview({
          ...selectedReview,
          data: {
            ...selectedReview.data,
            [id]: newValue,
          },
        });
      }
    }
  };

  // Update review in Firestore
  const updateReview = async (e) => {
    e.preventDefault();

    if (!selectedReview) {
      window.alert("Please select a review to update.");
      return;
    }
    const confirmed = window.confirm("Are you sure you want to update this review?");
    if(!confirmed){
      return;
    }

    try {
      const storage = getStorage();
      let imageUrl = reviewData.data.image;

      if (typeof reviewData.data.image === 'string' && reviewData.data.image.startsWith('blob:http')) {
        const response = await fetch(reviewData.data.image);
        const blob = await response.blob();

        const imageRef = ref(storage, `ReviewImages/${reviewData.data.pet_name}Review`);
        await uploadBytes(imageRef, blob);

        imageUrl = await getDownloadURL(imageRef);
      }
      
      console.log(imageUrl);
      console.log(reviewData.data);
      const reviewRef = doc(db, "review", selectedReview.id);
      const updateData = {
        image: imageUrl,
        pet_name: reviewData.data.pet_name,
        rating: reviewData.data.rating,
        review_text: reviewData.data.review_text,
      };

      await updateDoc(reviewRef, updateData);

      window.alert("Review updated successfully.");
    } catch (error) {
      window.alert("Error updating review: " + error.message);
    }
  };

  return (
    <div className="flex-col justify-center mx-8 my-16 max-w-7xl md:mx-auto">
      <div className="flex justify-center">
        <h1 className="text-4xl font-semibold md:text-5xl">Edit Review</h1>
      </div>
      <div>
        <h2 className="mt-10 text-2xl font-semibold text-center md:text-3xl">Select a review to edit</h2>
        <form className="flex-col mx-auto my-16 w-[500px]" onSubmit={updateReview}>
          <div className="flex items-center justify-between my-8">
            <label>Select Review:</label>
            <select
              onChange={handleReviewSelection}
              className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block p-2.5"
              defaultValue=""
            >
              <option value="" disabled hidden>
                Choose a review
              </option>
              {reviews.map((review) => (
                <option key={review.id} value={review.id}>
                  {review.data.pet_name}
                </option>
              ))}
            </select>
          </div>
          {/* Render form fields based on selectedReview */}
          {selectedReview && (
            <>
            <div className="flex items-center justify-between my-8">
              <label>Pet Name:</label>
              <input
                maxLength={30}
                onChange={handleInputChange}
                type="text"
                id="pet_name"
                value={reviewData.pet_name || selectedReview.data.pet_name} // Set value to reviewData if it exists or selectedReview
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block p-2.5"
                placeholder="This appears in review card (30 Max) characters"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Review:</label>
              <textarea
                onChange={handleInputChange}
                maxLength={220}
                type="text"
                id="review_text"
                value={reviewData.review_text || selectedReview.data.review_text} // Set value to reviewData if it exists or selectedReview
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block p-2.5"
                placeholder="This appears in review card (150 Max) characters"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Rating:</label>
              <input
                onChange={handleInputChange}
                type="number"
                step="0"
                min="0"
                max="5"
                id="rating"
                value={reviewData.rating || selectedReview.data.rating} // Set value to reviewData if it exists or selectedReview
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block p-2.5"
                placeholder="Rating 0-5"
                required
              />
            </div>
            <div className="flex items-center justify-between my-8">
              <label>Main Image:</label>
              <input
                onChange={handleInputChange}
                type="file"
                id="image"
                accept="image/*"
                className="w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                  focus:outline-none focus:border-[#005c7b] focus:ring-1 focus:ring-[#005c7b]
                  block p-2.5"
              />
            </div>
            <button className="flex w-32 p-2 mx-auto mt-20 border rounded-lg" type="submit">
              Submit to DB
            </button>
          </>
          )}
        </form>
        {/* Display selected review card */}
        <div className="flex justify-center h-[508px]">
          {selectedReview && (
            <ReviewCard data={reviewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditReview;