import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDZTA5KvRnrdXr_Cvzg0lPYrNOqFznhcQI",
  authDomain: "just-natural-paws.firebaseapp.com",
  projectId: "just-natural-paws",
  storageBucket: "just-natural-paws.appspot.com",
  messagingSenderId: "731869643432",
  appId: "1:731869643432:web:c4c9ba603a2ccdc828b89d",
  measurementId: "G-0GC78HS5KG",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export { db, auth };
