import React from "react";
import { Link } from "react-router-dom";
// import HeroVideo from '../../images/HeroVideo.mp4';

const Hero = () => {
  return (
    <div className="flex flex-col items-center justify-center mx-12 mt-16 w-7/8">
      <div className="justify-between max-w-6xl md:flex">
        <div className="justify-center mb-10 md:mb-0 md:flex md:flex-col md:w-1/2 md:pr-8">
          <h1 className="md:leading-tight max-w-lg text-4xl font-bold mb-8 md:text-[52px]">
            From one pet parent,{" "}
            <span className="text-[#005c7b] relative">
              to another.
              <span className="hidden lg:block absolute h-[3px] w-7 bg-[#005c7b] -right-8 bottom-[48px] rounded-xl transform -rotate-[20deg]"></span>
              <span className="hidden lg:block absolute h-[3px] w-9 bg-[#005c7b] -right-7 bottom-[65px] rounded-xl transform -rotate-45"></span>
              <span className="hidden lg:block absolute h-[3px] w-7 bg-[#005c7b] -right-2 bottom-[70px] rounded-xl transform -rotate-[70deg]"></span>
            </span>
          </h1>
          <p className="max-w-md mb-4 leading-8 md:text-base">
            It's that beautiful bond - the bond of love, care, and spending the
            most precious moments of our lives with the ones we love most.
          </p>
          <Link
            to="/Shop"
            className="font-bold text-xs bg-[#005c7b] text-white mb-8 px-12 py-3 rounded w-fit
          transition-transform transform hover:scale-110
          md:text-base"
          >
            Shop Now
          </Link>
        </div>
        <div className="flex justify-center w-full md:w-1/2 lg:h-[500px]">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="h-full min-h-[350px] rounded-2xl object-cover"
          >
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FHeroVideo.mp4?alt=media&token=11e7a232-ea95-48ce-baaa-9525331d821e"
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Hero;
