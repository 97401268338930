import React from 'react';
import QuantityButton from '../QuantityButton/QuantityButton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RemoveButton from '../RemoveButton/RemoveButton';

function CartRow(props){
    const cartItems = useSelector((state) => state.cart.items);
    
    const totalPrice = () => {
        let total = 0;
        for(const item of cartItems){
            total += item.price * item.quantity;
        }
        return total.toLocaleString("en-CA", {style:"currency", currency:"CAD"});
    }
  
    return(
        <div className={`flex flex-col justify-center rounded-lg ${props.popup ? 'px-2 py-4 md:mx-6 md:px-8' : 'px-4 py-4 md:mx-6 md:px-2'}`}>
            {cartItems.map((product) => (
                <div key={product.name} className={`flex flex-col mb-4 ${props.popup ? 'border-b' : 'border shadow-xl rounded-lg'}`}>
                    <div className="flex space-x-4">
                        <img src={product.images[0]} alt={product.name} className={`${props.popup ? 'h-auto max-w-[200px] -mx-12' : 'md:h-min my-auto -mx-8 max-w-[200px] md:max-w-[260px]'}`} />
                        <div className={`my-auto ${props.popup ? 'max-w-[200px]' : 'max-w-[250px] md:max-w-[400px]'}`}>
                            <p className={`mb-2 font-medium text-left ${props.popup ? 'text-sm' : 'text-sm md:text-base'}`}>{product.name}</p>
                            {!props.popup && 
                            <p className='pr-2 text-xs md:text-sm'>{product.description}</p>}
                            <p className="mb-2 text-sm text-left">{product.price.toLocaleString("en-CA", {style:"currency", currency:"CAD"})}</p>
                            {props.proceed ? (
                                <h4 className=' md:text-xl'>
                                    Qty: {product.quantity}
                                </h4>
                            ):(
                                <div className='flex items-center space-x-2'>
                                    <QuantityButton product={product} cartPopup={true}/>
                                    <RemoveButton product={product}/>
                                </div>)
                            }
                            <p className="my-2 text-sm text-left">{(product.price * product.quantity).toLocaleString("en-CA", {style:"currency", currency:"CAD"})}</p>
                        </div>
                    </div>
                </div>
            ))}
            
            <div className="flex items-center justify-end mt-2">
                {!props.proceed && <h1 className="mr-8 text-black">Total: {totalPrice()}</h1>}
                {props.popup === true &&
                <Link to="/CheckoutPage" className="px-4 py-2 text-white font-semibold bg-[#005c7b] rounded hover:bg-gray-800 hover:scale-105">Go To Cart</Link>}
            </div>
        </div>
    );
}

export default CartRow;