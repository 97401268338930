import React, {useState} from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

const ProductPageInfo = (props) => {
    const [expanded, setExpanded] = useState(false);

    const changeSign = () => {
        setExpanded(!expanded);
    }
  return (
    <div className='max-w-3xl mx-8 mt-10 border-b-2'>
        <div className='flex mb-6'>
            <button className='flex my-auto' onClick={changeSign}>
                <div>
                    {expanded ? <FaMinus color='#005c7b'/> : <FaPlus/>}
                </div>
                <h4 className={`${expanded && 'text-[#005c7b]'} ml-6 -mt-1 text-lg text-left font-semibold`}>{props.heading}</h4>
            </button>
        </div>
        {expanded &&
        <div className='whitespace-pre-line border-l-2 border-[#005c7b] mx-12 mb-6 pl-3 max-w-[600px]'>
            <p>{props.info}</p>
        </div>}
    </div>
  )
}

export default ProductPageInfo;