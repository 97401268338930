import React, { useEffect } from "react";
// import Beach1 from "../../images/Beach1.jpg";
// import CatPic10 from "../../images/CatPic10.jpg";
// import DogPic9 from "../../images/DogPic9.jpg";
// import Beach2 from "../../images/Beach2.jpg";
// import CatDogPic11 from "../../images/CatDogPic11.jpg";
// import DogPic12 from "../../images/DogPic12.jpg";
import AboutBanner from "./AboutBanner";
import AboutImgCollage from "./AboutImgCollage";
import AboutInfoSection from "./AboutInfoSection";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const foundingStory = {
    heading: "Founding story",
    info: (
      <>
        Our furry friends have taught us the value of unconditional love and
        unwavering loyalty. Their presence in our lives encouraged us to create
        a business that reflects these qualities, one that supports the
        well-being of pets around the world.
      </>
    ),
  };
  const misson = {
    heading: "Mission and Vision",
    info: (
      <>
        Thus, our mission began - to craft treats that are not only delectable
        but also nourishing, using only whole food, whole ingredients. Our
        dedication to quality is not just about providing the best for our own
        pets; it's about extending that commitment to your cherished companions
        as well.
        <br />
        <br />
        We believe that pets deserve the same level of care and attention as any
        family member. That's why we go the extra mile to ensure our treats are
        not just suitable but worthy of being called human-grade.
      </>
    ),
  };
  const promise = {
    heading: "Our promise",
    info: (
      <>
        Our commitment doesn't end there. We are proud to give back by donating
        1% of our profits from every pouch to animal welfare efforts worldwide.
        It's our way of supporting the larger community of animals who enrich
        our lives in countless ways.
      </>
    ),
  };
  const thankyou = {
    heading: "Thank you for being a part of our journey",
    info: (
      <>
        Together, we're nourishing pets with the goodness of whole ingredients,
        and making a positive impact on animal welfare, one treat at a time.
        <br />
        <br />
        Welcome to a world where treats are made with heart, and pets are
        cherished like family. With love, from one pet parent to another.
      </>
    ),
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center m-10 w-7/8">
        <div className="flex-col justify-center justify-between w-full max-w-6xl p-8">
          <h1 className="text-4xl font-bold text-center md:text-5xl">
            About us
          </h1>
          <p className="mx-auto my-6 md:text-center text-[17.5px] max-w-[830px] leading-8">
            Welcome to our family-owned pet treat company, where the love for
            animals drives every step of our journey. At the heart of our
            inspiration are two cherished companions - our loyal dog and
            affectionate cat. These furry family members not only bring us
            immeasurable joy but also ignited the passion behind our healthy pet
            treat business.
          </p>
          <div className="flex w-full max-w-5xl mx-auto overflow-hidden h-96">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FBeach1.jpg?alt=media&token=21c04dcf-3d1d-4440-a3df-35cfc721e6a0"
              }
              alt="img1"
              className="object-cover w-full rounded-2xl"
            />
          </div>
        </div>
      </div>
      <AboutInfoSection
        heading={foundingStory.heading}
        info={foundingStory.info}
        image={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FCatPic10.jpg?alt=media&token=823e6679-b12a-4270-ab7b-f0a65d018c2c"}
        reverse={false}
      />
      <AboutInfoSection
        heading={misson.heading}
        info={misson.info}
        image={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic9.jpg?alt=media&token=e1096a5c-df7a-44fe-a6ca-355101719386"}
        reverse={true}
      />
      <AboutBanner image={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FBeach2.jpg?alt=media&token=df1e66ce-eb42-4bd2-9a85-e971dd7b0c73"} />
      <AboutInfoSection
        heading={promise.heading}
        info={promise.info}
        image={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic1.jpg?alt=media&token=60f61403-e4bb-4b72-9215-c2f593de4f8e"}
        reverse={false}
      />
      <AboutImgCollage />
      <AboutInfoSection
        heading={thankyou.heading}
        info={thankyou.info}
        image={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FDogPic12.jpg?alt=media&token=cea262a0-a4d3-499a-bd36-1a3d58db6512"}
        reverse={true}
      />
    </>
  );
}

export default About;
